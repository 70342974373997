import React, { Fragment } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import ErrorIcon from '@material-ui/icons/Error';
import CloseIcon from '@material-ui/icons/Close';
import SnackbarContent from '@material-ui/core/SnackbarContent';

const SnackBarWarning = (props) => {
  const [isOpen, setIsOpen] = React.useState(true);

  const handleClose = () => {
    setIsOpen(false);
    props.onClose();
  };

  return (
    <Fragment>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={isOpen}
        onClose={() => handleClose}
      >
        <SnackbarContent
          aria-labelledby={props.aria}
          className="gw__snackbar-warning-content"
          message={
            <div className="gw__snackbar-close" aria-label="Close" onClick={() => handleClose}>
              <div className="d-flex align-items-center">
                <ErrorIcon className="mr-2" />
                {props.message}
              </div>
              <CloseIcon className="gw__snackbar-close-icon"/>
            </div>
          }
        />
      </Snackbar>
    </Fragment>
  );
}

export default SnackBarWarning;
