import { authApi } from '../api';

const api = authApi();

/**
 * Fetch TOS response for logged in user
 */
const fetchTOS = async () => {
  const tosResponse = await api.get('/tos');

  if (tosResponse && tosResponse.data) {
    return tosResponse.data;
  }

  return [];
}

export {
  fetchTOS,
};
