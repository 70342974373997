import React, { useEffect, useRef, useState, useContext } from 'react';
import { Transition } from 'react-transition-group';
import { useLocation } from 'react-router-dom'
import { ChevronLeft, ChevronRight, ReportProblemOutlined, NotificationsActiveOutlined } from '@material-ui/icons';
import { Carousel, CarouselItem } from 'reactstrap';
import { CountryContext } from '../context/CountryContext';
import { authApi } from '../api';
import {convertFromRaw} from 'draft-js';
import {stateToHTML} from 'draft-js-export-html';

const api = authApi();

function ShowAnnouncement() {
  let location = useLocation();
  const [countryState] = useContext(CountryContext);
  const [announcementType, setAnnouncementType] = useState(1);
  const [announcementItems, setAnnouncementItems] = useState([]);
  const [announcementsToShow, setAnnouncementsToShow] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [visible, setVisible] = useState(true);
  const [globalDismissed, setGlobalDismissed] = useState(false)
  const [dismissedCountryIds, setDismissedCountryIds] = useState([]);
  const announcementBar = useRef(null);
  const [transitionStyles, setTransitionStyles] = useState({
    entering: { opacity: 1 },
    entered:  { opacity: 1 },
    exiting: { marginTop: '-45px', opacity: 1 }, 
    exited: { marginTop:  '-45px', opacity: 0},
  });

  const carouselPolyfill = () => {
    (function () {
      function CustomEvent ( event, params ) {
        params = params || { bubbles: false, cancelable: false, detail: undefined };
        var evt = document.createEvent( 'CustomEvent' );
        evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
        return evt;
       }
    
      CustomEvent.prototype = window.Event.prototype;
    
      window.CustomEvent = CustomEvent;
    })();
  };

  useEffect(() => {
    carouselPolyfill();
    fetchAnnouncement();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    
    if (location.pathname !== '/') {
      const result = [];

      if (countryState.selectedDestinationCountry) {
        announcementItems.forEach(el => {
          if (el.selectedAnnouncementCountries) {
            if (el.selectedAnnouncementCountries.find(announcementCountry => announcementCountry.country.id === countryState.selectedDestinationCountry.id)
              && !dismissedCountryIds.includes(countryState.selectedDestinationCountry.id)) {
              result.push(el);
            }
          }
        });
      } else {
        announcementItems.forEach(el => {
          if (el.isGlobal && !globalDismissed) {
            result.push(el);
          }
        });
      }
  
      if (result.length > 0) {
        setAnnouncementType(result[0].type);
        setVisible(true);
      }
      setAnnouncementsToShow(result);
      setActiveIndex(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryState.selectedDestinationCountry, location.pathname]);

  // Hide the entire announcement bar
  const onDismiss = (e) => {
    setTransitionStyles({
      entering: { opacity: 1 },
      entered:  { opacity: 1 },
      exiting: { marginTop: -announcementBar.current.clientHeight, opacity: 1 }, 
      exited: { marginTop: -announcementBar.current.clientHeight, opacity: 0},
    });
    setVisible(false);
    if (location.pathname === '/') {
      setGlobalDismissed(true);
    } else {
      dismissedCountryIds.push(countryState.selectedDestinationCountry.id);
      setDismissedCountryIds(dismissedCountryIds);
    }
  };

  // Retrieve announcements stored in the database via the client backend.
  const fetchAnnouncement = async () => {
    const announcementResponse = await api.get('/announcements');

    if (announcementResponse && announcementResponse.data[0]) {
      const result = [];
      announcementResponse.data.forEach(el => {
        if(jsonChecker(el.text)){
          let resultAnnouncements = JSON.parse(el.text);
          const contentState = convertFromRaw(JSON.parse(JSON.stringify(resultAnnouncements)));
          let html = stateToHTML(contentState);
          el.text = html;
        }
        if (el.isGlobal) {
            result.push(el);
        }
      });

      setAnnouncementItems(announcementResponse.data);
      if (result.length > 0) {
        setAnnouncementsToShow(result);
        setAnnouncementType(result[0].type);
      }
    }
  };

  //Check if input is a valid JSON file
  const jsonChecker = (item) => {
    item = typeof item !== 'string'
    ? JSON.stringify(item)
    : item;

    try {
        item = JSON.parse(item);
    } catch (e) {
        return false;
    }

    if (typeof item === 'object' && item !== null) {
        return true;
    }

    return false;
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === announcementsToShow.length - 1 ? 0 : activeIndex + 1;
    setAnnouncementType(announcementsToShow[nextIndex].type);
    setActiveIndex(nextIndex);
  }

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? announcementsToShow.length - 1 : activeIndex - 1;
    setAnnouncementType(announcementsToShow[nextIndex].type);
    setActiveIndex(nextIndex);
  }

  const defaultStyle = {
    transition: `all ${300}ms ease-in-out`,
    opacity: 1,
  }

  const getAnnouncementTypeClass = () => {
    switch (announcementType) {
      case 2:
        return 'alert-danger';
      case 3:
        return 'alert-warning';
      default:
        return 'alert-success';
    }
  }

  const getIcon = (type) => {
    switch (type) {
      case 2:
        return <ReportProblemOutlined fontSize="inherit" />;
      case 3:
        return <NotificationsActiveOutlined fontSize="inherit" />;
      default:
        return <i className="icon-broadcast_special" />;
    }
  }

  const slides = announcementsToShow.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.id}
      >
        <div className="gw-announcement__item">
          <span className="gw-announcement__icon">
            {
              getIcon(item.type)
            }
          </span>
          <span className="gw-announcement__text">
            <b className="gw-announcement__title">
              { item.title.concat(' ') }
            </b>
            {/* show HTML code to display format and clickable links */}
            <div className="gw-announcement__text" dangerouslySetInnerHTML={{ __html:item.text.concat(' ') }}/>
          </span>
        </div>
      </CarouselItem>
    );
  });

  // only show this banner if there is an announcement being retrieved.
  if (announcementsToShow.length <= 0) {
    return null;
  }

  return (
    <Transition in={visible} timeout={300} unmountOnExit>
    {state => (
      <div style={{
        ...defaultStyle,
        ...transitionStyles[state]
      }} className="d-flex flex-column h-100">
        <div className={`gw-announcement ${getAnnouncementTypeClass()}`} ref={ announcementBar }>
          <Carousel
            activeIndex={activeIndex}
            next={next}
            previous={previous}
            interval={false}
          >
            { slides  }
            <div className={`carousel-control-prev ${announcementsToShow.length > 1 ? '' : 'd-none'}`} onClick={previous}>
              <ChevronLeft fontSize="inherit"/>
            </div>
            <div className={`carousel-control-next ${announcementsToShow.length > 1 ? '' : 'd-none'}`} onClick={next}>
              <ChevronRight fontSize="inherit"/>
            </div>
          </Carousel>
          
          <button type="button" className="close" aria-label="Close" onClick={(e) => onDismiss(e)}><span aria-hidden="true">×</span></button>
        </div>
       </div>
     )}
   </Transition>
  );
};

export default ShowAnnouncement;
