import React, { Fragment } from 'react';
import HighlightOffOutlined from '@material-ui/icons/HighlightOffOutlined';
import {convertFromRaw} from 'draft-js';
import {stateToHTML} from 'draft-js-export-html';
import config from '../config';


const ResultsFullAssessmentRequired = ({ customOutput, resetAssessment }) => {
  const convertContent = (content) => {
    const contentState = convertFromRaw(JSON.parse(content));
    let html = stateToHTML(contentState);
    return html;
  };

  return (
    <Fragment>
      <div className="col-12">
        <div className="d-flex justify-content-between align-items-center">
          <h1 className="gw__results-title">Full assessment required</h1>
          <HighlightOffOutlined className="gw__results-failure" />
        </div>

        { customOutput !== ''
          ? <p className="gw__results-subtext" dangerouslySetInnerHTML={{__html: convertContent(customOutput)}}></p>
          : <p className="gw__results-subtext">
              The candidate's position needs a detailed review by an immigration specialist to determine work eligibility.
              Please <a href={config.resultsFullAssessmentRequiredMailTo}>contact us</a> to request a detailed assessment including details of the hiring or assignment/transfer 
              location for your candidate and our team will let you know what information they require to proceed.
            </p>
        }
      </div>

      <div className="d-flex justify-content-end mt-5">
        <button className="btn btn-primary" onClick={resetAssessment}>CLOSE</button>
      </div> 
    </Fragment>
  );
};

export default ResultsFullAssessmentRequired;
