import React, { useEffect, useReducer } from 'react';
import { Route, HashRouter } from 'react-router-dom';

import LandingPage from './screens/LandingPage';
import NavigationBar from './components/NavigationBar';
import Assessment from './screens/Assessment';
import Results from './screens/Results';
// import Reporting from './screens/Reporting';
import SessionExpired from './components/SessionExpired';
import SessionManager from './components/SessionManager';

// CSS
import './fonts/deloitte-special-use-icon-font.eot';
import './fonts/deloitte-special-use-icon-font.otf';
import './fonts/deloitte-special-use-icon-font.ttf';
import './fonts/deloitte-special-use-icon-font.woff';
import './fonts/deloitte-special-use-icon-font.woff2';
import './App.scss';

// Context and Reducers
import { AssessmentContext } from './context/AssessmentContext';
import { AssessmentReducer } from './reducers/AssessmentReducer';
import { CountryContext } from './context/CountryContext';
import { CountryReducer } from './reducers/CountryReducer';
import { fetchJobPositions, fetchJobLevels } from './services/CompanyService';
import { fetchDestinationCountries, fetchCountries, fetchFieldsOfStudy } from './services/CountryService';
import { fetchTOS } from './services/UserService';
import { UserContext } from './context/UserContext';
import { UserReducer } from './reducers/UserReducer';
import { extendUserSession } from './services/AuthenticationService';
import config from './config';

const App = () => {
  const initialAssessmentState = {
    onlyUsePremiumTimes: config.onlyUsePremiumTimes,
    onlyUseStandardTimes: config.onlyUseStandardTimes,
    currentExistingVisaIndex: 0,
    previousIndex: 0,
    currentIndex: 0,
    previousRequirementIndex: 0,
    currentRequirementIndex: 0,
    eligibleWorkPermits: [],
    ineligibleWorkPermits: [],
    existingVisaDone: false,
    existingVisaResult: null,
    requirementResponses: new Map(),
    selectedCitizenship: null,
    selectedExistingVisa: null,
    selectedFieldOfStudy: null,
    selectedJobPosition: null,
    selectedSourceCountry: null,
    clientSpecificQuestion : '',
    startTime: null,
    questionsCompletionStatus: {
      existingVisa: false,
      sourceCountry: false,
      jobPosition: false,
      citizenship: false,
      requirements: {},
    },
  };

  const initialCountryState = {
    countries: [],
    destinationCountries: [],
    fieldsOfStudy: [],
    jobPositions: [],
    jobLevels: [],
    selectedDestinationCountry: null,
    selectedDestinationCountryExistingVisas: [],
    selectedDestinationCountryRequirements: [],
    sessionTimeout: null,
  };

  const initialUserState = {
    isLoggedIn: false,
    isForbidden: false,
    tosStatus: false,
    hasCheckedUserStatus: false,
  };

  // Context and Reducer Setup
  const assessmentReducer = (state, action) => AssessmentReducer(state, action);
  const [assessmentState, assessmentDispatch] = useReducer(assessmentReducer, initialAssessmentState);
  const countryReducer = (state, action) => CountryReducer(state, action);
  const [countryState, countryDispatch] = useReducer(countryReducer, initialCountryState);
  const userReducer = (state, action) => UserReducer(state, action);
  const [userState, userDispatch] = useReducer(userReducer, initialUserState);


  useEffect(() => {
    const checkSession = async () => {
      // Try to extend session initially in order to confirm cookies were set.
      const response = await extendUserSession();

      if (response && response.status === 201) {
        userDispatch({
          type: 'LOGIN_SUCCESS',
          isLoggedIn: true,
          isForbidden: false,
          hasCheckedUserStatus: true,
        });
      } else if(response && response.status === 403) {
        userDispatch({
          type: 'LOGIN_FORBIDDEN',
          isLoggedIn: true,
          isForbidden: true,
          hasCheckedUserStatus: true,
        });
      } else {
        userDispatch({
          type: 'LOGIN_FAILED',
          isLoggedIn: false,
          isForbidden: false,
          hasCheckedUserStatus: true,
        });
      }
    };

    checkSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(userState.isLoggedIn) {
      handleTOS()
      fetchCountryData();
    }
  }, [userState.isLoggedIn])

  const handleTOS = async () => {

    const tosData = await fetchTOS();

    userDispatch({
      type: 'TOS',
      tosStatus: tosData.length === 0,
    });
  }

  const fetchCountryData = async () => {
    const countriesResponse = await fetchCountries();
    const destinationCountryResponse = await fetchDestinationCountries();
    const fieldsOfStudyResponse = await fetchFieldsOfStudy();
    const jobPositionsResponse = await fetchJobPositions();
    const jobLevelResponse = await fetchJobLevels();

    countryDispatch({
      type: 'FETCH_COUNTRIES',
      countries: countriesResponse,
      destinationCountries: destinationCountryResponse,
      fieldsOfStudy: fieldsOfStudyResponse,
      jobPositions: jobPositionsResponse,
      jobLevels: jobLevelResponse,
    });
  };

  return (
    <div className="gw__app-container" id='__nonPrintable__'>
      <UserContext.Provider value={[userState, userDispatch]}>
        <CountryContext.Provider value={[countryState, countryDispatch]}>
          <AssessmentContext.Provider value={[assessmentState, assessmentDispatch]}>
            <SessionManager />
            {
              userState.isLoggedIn && !userState.isForbidden
                ?
                <HashRouter basename="/">
                  <NavigationBar />
                  <div className="gw__app__main">
                    <Route exact path="/" component={LandingPage} />
                    <Route exact path="/assessment" component={Assessment} />
                    <Route exact path="/results" component={Results} />
                    {/* <Route exact path="/reporting" component={Reporting} /> TODO - ADD REPORTING */}
                    <Route exact path="/expired" component={SessionExpired} />
                  </div>
                </HashRouter>
                :
                <div className="gw__app__main"><LandingPage /></div>
            }
          </AssessmentContext.Provider>
        </CountryContext.Provider>
      </UserContext.Provider>
    </div>
  );
};

export default App;
