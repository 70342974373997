import React, { useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { AssessmentContext } from '../context/AssessmentContext';

const ResultsProcessingTime = ({ workPermits }) => {

	const [assessmentState] = useContext(AssessmentContext);
	const [totalProcessingTime, setTotalProcessingTime] = useState(0);
	const [deloitteProcessingTime, setDeloitteProcessingTime] = useState(0);
	const [processingTime, setProcessingTime] = useState(0);
	const [visaProcessingTime, setVisaProcessingTime] = useState(0);
	const [inCountryProcessingTime, setInCountryProcessingTime] = useState(0);
	const [isPremium, setIsPremium] = useState(false);

	useEffect(() => {
		// Use premium if it has been configured for any of the time segments
		// premiumProcessingTime, totalPremiumProcessingTime or visaPremiumProcessingTime
		if (workPermits.length > 0) {
			setDeloitteProcessingTime(workPermits[0].deloitteProcessingTime);
			setInCountryProcessingTime(workPermits[0].inCountryProcessingTime);

			// note: onlyUsePremium is static flag set on client-by-client basis

			if (assessmentState.onlyUsePremiumTimes) {
				if (workPermits[0].totalPremiumProcessingTime > 0) {
					setIsPremium(true);
					setTotalProcessingTime(workPermits[0].totalPremiumProcessingTime);
					setProcessingTime(workPermits[0].premiumProcessingTime);
	
					if (!workPermits[0].nonVisaNation) {
						setVisaProcessingTime(workPermits[0].visaPremiumProcessingTime);
					}
				} else {
					setTotalProcessingTime(workPermits[0].totalProcessingTime);
					setProcessingTime(workPermits[0].processingTime);
	
					if (!workPermits[0].nonVisaNation) {
						setVisaProcessingTime(workPermits[0].visaProcessingTime);
					}
				}
			} else if (assessmentState.onlyUseStandardTimes) {
				setTotalProcessingTime(workPermits[0].totalProcessingTime);
				setProcessingTime(workPermits[0].processingTime);

				if (!workPermits[0].nonVisaNation) {
					setVisaProcessingTime(workPermits[0].visaProcessingTime);
				}
			} 
		}

	}, [workPermits, assessmentState.onlyUsePremiumTimes, assessmentState.onlyUseStandardTimes]);

  return (
    <>
	    {
		    workPermits.length > 0 &&
				totalProcessingTime > 0 &&
		    <div className="row my-5">
			    <div className="col-12">
						<h6 className="gw__results-permit-heading m-0">
							<i className="icon-stopwatch_special gw__results-icon" />
							Estimated Immigration Processing Times
						</h6>
					</div>
			
			    <div className="col-12">
				    <p className="gw__results-permit-description mb-0">
						This indicative processing time starts when you begin the immigration application process.
						</p>
				    <p className="gw__results-processing-time my-3">{totalProcessingTime} weeks</p>
			    </div>
			
			    <div className="col-12 d-flex w-100 gw__results-progress-bar-container">
				    {
					    deloitteProcessingTime > 0 &&
					    <div style={{
						    width: (Math.round((deloitteProcessingTime * 100) / totalProcessingTime).toFixed(2)) + '%',
						    minWidth: '15%'
					    }}>
						    <div className="gw__results-document-bar" />
								<h6 className="gw__results-processing-time-weeks">
									{ deloitteProcessingTime } { deloitteProcessingTime > 1 ? ' weeks' : ' week' }
								</h6>
						    <p className="gw__results-processing-time-subtext">Document + Application Preparation</p>
					    </div>
				    }
				    {
					    processingTime > 0 &&
					    <div style={{
						    width: (Math.round((processingTime * 100) / totalProcessingTime).toFixed(2)) + '%',
						    minWidth: '15%'
					    }}>
								<div className="gw__results-government-bar" />
								<h6 className="gw__results-processing-time-weeks">
									{ processingTime } { processingTime > 1 ? ' weeks' : ' week'}
								</h6>
						    <p className="gw__results-processing-time-subtext">
									{ isPremium ? 'Work Permit Premium Processing' : 'Work Permit Processing' }
								</p>
					    </div>
				    }
				    {
					    visaProcessingTime > 0 &&
					    <div style={{
						    width: (Math.round((visaProcessingTime * 100) / totalProcessingTime).toFixed(2)) + '%',
						    minWidth: '15%'
					    }}>
						    <div className="gw__results-visa-bar" />
								<h6 className="gw__results-processing-time-weeks">
									{ visaProcessingTime } { visaProcessingTime > 1 ? ' weeks' : ' week' }
								</h6>
						    <p className="gw__results-processing-time-subtext">
									{ isPremium ? 'Visa Premium Processing' : 'Visa Processing' }
								</p>
					    </div>
				    }
				    {
					    inCountryProcessingTime > 0 &&
					    <div style={{
						    width: (Math.round((inCountryProcessingTime * 100) / totalProcessingTime).toFixed(2)) + '%',
						    minWidth: '15%'
					    }}>
						    <div className="gw_results-in-country-processing-time" />
								<h6 className="gw__results-processing-time-weeks">
									{ inCountryProcessingTime } { inCountryProcessingTime > 1 ? ' weeks' : ' week' }
								</h6>
						    <p className="gw__results-processing-time-subtext">Processing Time in Destination Country to Start Work</p>
					    </div>
				    }
			    </div>
		    </div>
	    }
    </>
  );
}

export default ResultsProcessingTime;
