import { authApi } from '../api';
const api = authApi();

const logout = async () => {
  try {
    await api.post('/auth/logout');
    window.location.replace('/');
  } catch (error) {
    console.error('goWorkLogout failed');
    window.location.reload();
  }
};

const extendUserSession = async () => {
  const res = await api.post('/auth/extend');

  return res;
};

export {
  logout,
  extendUserSession,
};
