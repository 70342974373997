export const UserReducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        isLoggedIn: action.isLoggedIn,
        isForbidden: action.isForbidden,
        hasCheckedUserStatus: action.hasCheckedUserStatus,
      };
    case 'LOGIN_FAILED':
      return {
        ...state,
        isLoggedIn: action.isLoggedIn,
        isForbidden: action.isForbidden,
        hasCheckedUserStatus: action.hasCheckedUserStatus,
      };
    case 'LOGIN_FORBIDDEN':
      return {
        ...state,
        isLoggedIn: action.isLoggedIn,
        isForbidden: action.isForbidden,
        hasCheckedUserStatus: action.hasCheckedUserStatus,
      };
    case 'LOGOUT':
      return {
        ...state,
        isLoggedIn: false,
        isForbidden: false,
      };
    case 'TOS':
      return {
        ...state,
        tosStatus: action.tosStatus,
      };
    default:
      return state;
  }
};

