import React from 'react'
import DeloitteLogo from '../images/Deloitte_new_logo.png'
import GoWorkLogo from '../images/Go_Work_new_logo.png'

const Logo = () => {
  let a = window.location.href.split('#');

  return (
    <>
    {
      a[1] === '/' ? 
      <img src={DeloitteLogo} alt="Deloitte Logo" style={{width: '140px', margin: 'auto', display: 'block'}}/>
      :
      <img src={GoWorkLogo} alt="GoWork Logo" style={{width: '120px', margin: 'auto', display: 'block'}}/>
    }
    </>
  )
}

export default Logo
