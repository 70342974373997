import React, { useContext, useEffect, useState } from 'react';
import AssessmentQuestion from '../components/AssessmentQuestion';
import { AssessmentContext } from '../context/AssessmentContext';
import { CountryContext } from '../context/CountryContext';
import config from '../config';

const SelectJobPosition = () => {
  // Context Variables
  const [assessmentState, assessmentDispatch] = useContext(AssessmentContext);
  const [countryState] = useContext(CountryContext);

  // State Variables
  const [jobPositionDropdownOptions, setJobPositionDropdownOptions] = useState([]);

  useEffect(() => {
    buildJobPositionsDropdown();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const buildJobPositionsDropdown = () => {
    let tempJobPositions = [];

    for (const jobPosition of countryState.jobPositions) {
      tempJobPositions.push({
        label: jobPosition.jobFamily,
        value: jobPosition,
      });
    }

    setJobPositionDropdownOptions(tempJobPositions.sort((a, b) => a.label.localeCompare(b.label)));
  };

  const handleJobPositionSelection = event => {
    assessmentDispatch({
      type: 'SELECT_JOB_POSITION',
      selectedJobPosition: event,
    });
  };

  return (
    <AssessmentQuestion
      question="What role is the candidate applying for?"
      description={<p>Please begin typing the job family to see options or select from the drop down menu. If there is no close match for the job family please
        <a className="gw__assessment-description-link" href={config.resultsFullAssessmentRequiredMailTo}> contact us </a>to request a custom assessment of the role.</p>}
      onChangeHandler={handleJobPositionSelection}
      options={jobPositionDropdownOptions}
      placeholder="Select job family"
      value={assessmentState.selectedJobPosition ? assessmentState.selectedJobPosition : null}
    />
  );
};

export default SelectJobPosition;
