import React from 'react'

const IconCheckmark = () => {
  return (
    <svg viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <circle stroke="#8DDE43" strokeWidth="2" cx="30" cy="30" r="29"/>
        <path d="M43.71 20.29a1 1 0 0 0-1.41 0L23 39.59l-6.29-6.29a1 1 0 0 0-1.41 1.41l7 7a1 1 0 0 0 1.41 0l20-20a1 1 0 0 0 0-1.42z" fill="#8DDE43" fillRule="nonzero"/>
        <path d="M6 6h48v48H6z"/>
      </g>
    </svg>

  )
}

export default IconCheckmark
