import React, { useContext, useState, useEffect } from 'react';
import { AssessmentContext } from '../context/AssessmentContext';
import Select, { components } from 'react-select';
import { CountryContext } from '../context/CountryContext';

const ExistingVisaSelection = () => {
  // Context variables
  const [assessmentState, assessmentDispatch] = useContext(AssessmentContext);
  const [countryState] = useContext(CountryContext);

  // State variables
  const [existingVisaDropdownOptions, setExistingVisaDropdownOptions] = useState([]);

  useEffect(() => {
    buildExistingVisaDropdown();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const buildExistingVisaDropdown = () => {
    let tempExistingVisas = [];

    for (const existingVisa of countryState.selectedDestinationCountryExistingVisas) {
      tempExistingVisas.push({
        label: existingVisa.name,
        value: existingVisa,
        response: existingVisa, // Currently needed to ensure H1B only returned if H1B selected, will need refactoring
      });
    }

    setExistingVisaDropdownOptions(tempExistingVisas);
  }

  const handleExistingVisaSelection = event => {
    assessmentDispatch({
      type: 'SELECT_EXISTING_VISA',
      selectedExistingVisa: event,
    });
  };

  const continueButtonHandler = () => {
    assessmentDispatch({ type: 'INCREMENT_EXISTING_VISA_INDEX' });
  };

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <i className="icon-simple_arrow_down_special gw__requirement-select-icon" />
      </components.DropdownIndicator>
    );
  };

  return (
    <div className="row">
      <div className="col-12 d-flex justify-content-between align-items-center mb-3">
        <span className="gw__existing-visa-header">What visa does the candidate currently hold?</span>
      </div>

      <div className="col-12">
        <p className="gw__existing-visa-subtext">
          If the candidate is in possession of more than one visa for the destination country, please select their primary visa type
          from the below list.
        </p>
      </div>

      <div className="col-12">
        <Select
          className="gw__requirement-react-select-container"
          classNamePrefix="gw__requirement-react-select"
          components={{DropdownIndicator}}
          onChange={event => handleExistingVisaSelection(event)}
          options={existingVisaDropdownOptions}
          placeholder="Select existing visa"
          value={assessmentState.selectedExistingVisa ? assessmentState.selectedExistingVisa : null}
        />
      </div>

      <div className="col-12 d-flex justify-content-end mt-5">
        <button className="btn btn-primary" onClick={continueButtonHandler} disabled={!assessmentState.selectedExistingVisa}>NEXT</button>
      </div>
    </div>
  );
};

export default ExistingVisaSelection;
