import React from "react";

const TermsOfUseContent = () => {
  return (
    <div>
      <h5 className="font-weight-extra-bold">
        Your right to use GoWork (the "Tool") is subject to your acceptance of the terms set out below. By clicking the "I accept" button you agree to these Terms of Use.
      </h5>
      <h5 className="font-weight-extra-bold">
        These Terms of Use, which may be updated from time to time, should be read in conjunction with the{" "}
        <a className="tos_link" href="https://app4.iastax.com/IASTCPortal/dko/main.aspx#PRIVACY" target='_blank' rel='noopener noreferrer'>
          <u>GlobalAdvantage Terms of Use</u>
        </a>
        {" "}which can be found by following this link.
      </h5>
      {/* <h5 className="font-weight-extra-bold">Description of the tool</h5>
      <p className="tos_paragraph font-weight-light">
      The GoWork Tool is a web based application intended to provide a preliminary work permit eligibility assessment to recruiters, HR and mobility professionals.   
The tool uses decision tree logic to determine if a suitable work permit is available information based on basic candidate and role information entered into the tool.  It delivers a single output detailing the indicative processing time for that permit, providing an estimated start date based on immigration processing times only.
The tool does not collate candidate specific personal data and as such should be used as a guideline to determine eligibility for a specific permit in the intended location rather than relied upon for a final eligibility determination for which detailed personal and role information is required.
      </p> */}

      {/* <h5 className="font-weight-extra-bold">Use of the tool</h5> */}
      <p className="tos_paragraph font-weight-light">
        This Tool is for use by certain personnel of (i) each member firm of Deloitte Touche Tohmatsu Limited (“<b>DTTL</b>”), 
        (ii) and the affiliates of such member firms (each a “<b>Deloitte Firm</b>”), and (ii) clients and former clients and other external users invited to use this Tool by a Deloitte 
        Firm.  DTTL and the Deloitte Firms and any of their related entities are referred to herein collectively as the “<b>Deloitte Network</b>”. 
        <br /><br />
        DTTL (also referred to as “<b>Deloitte Global</b>”) and each of its member firms and related entities are legally separate and independent entities, which 
        cannot obligate or bind each other in respect of third parties.  DTTL and each DTTL member firm and related entity is liable only for its own acts 
        and omissions, and not those of each other.  DTTL does not provide technology or services to clients. Please see{" "}
        <a className="tos_link" href="www.deloitte.com/about" target='_blank' rel='noopener noreferrer'>
          <u>www.deloitte.com/about</u>
        </a>
        {" "}to learn more. 
        <br /><br />
        As noted above, each Deloitte Firm may invite personnel of clients and other external users to use this Tool pursuant to these Terms of Use.  
        If you are such an invitee, these Terms of Use are applicable to you (and further references below to “<b>you</b>” and “<b>your</b>” shall be to you as such an invitee).
        <br /><br />
        These Terms of Use shall be a legally binding agreement between you on the one hand and the Deloitte Firm that invited you to this Tool on 
        the other (referred to herein as "<b>we</b>", "<b>us</b>" or “<b>our</b>”).
        <br /><br />
        On the condition that you comply with all of your obligations under these Terms of Use, we grant you a limited, revocable, 
        non-exclusive, non-assignable, non-sublicensable right to access and use this Tool only as expressly permitted herein. 
        <br /><br />
        “<b>External users</b>” means a person or entity (other than Deloitte or personnel of Deloitte) that is invited to use the Tool by Deloitte.
        <br /><br />
        You confirm that you lawfully hold a password in connection with access to and use of the Tool.  
        <br /><br />
        You acknowledge and accept that you are permitted to access, view and use the Tool on this website and copy and print forms, 
        data and other material created by the proper use of the Tool (the “<b>Output</b>”) subject to the following conditions.  
      </p>

      <div className="tos_paragraph font-weight-light">
        <ul>
            1.1	All intellectual property and other proprietary rights in the Tool are exclusively owned by Deloitte or its licensors and are protected by international intellectual property laws and conventions.  No intellectual property or other rights shall be transferred to you or to any External Users.
          <br /><br />1.2	The Tool and the Output shall be used solely for the purpose of supporting External Users with the initiation, tracking, reporting and collation of information related to the immigration process.
          <br /><br />1.3	You accept that the Tool does not constitute immigration, tax or other professional advice of any kind and should not be used as a substitute for professional advice or services.
          <br /><br />1.4	You accept that the preliminary work permit eligibility assessment and processing time calculated by the Tool is indicative only and based on inputs entered.
          <br /><br />1.5	Through your acceptance of these Terms of Use, you hereby acknowledge that you are not prohibited for any reason from entering into these Terms of Use and that you ensure that you only access the Tool from devices and networks where adequate security measures are in place to protect your personal information and data.
          <br /><br />1.6	Deloitte makes no guarantee of the availability, accuracy, or reliability of the information made available through the Tool and Deloitte or its licensors may change the information available on or the features and functions of the Tool at any time, with or without notice to you.  In addition, information received from the Tool is necessarily dependent on the information your or any third party provides on your behalf.  Deloitte also has no liability for the accuracy of the information it receives, either from you or any government entity.

          <br /><br />1.7 <b>YOU MAY NOT NOR PERMIT OTHERS TO:</b>
          <ul>
            a)	use, copy, distribute or transfer the Tool and Output except as expressly permitted by these Terms of Use;
            <br />b)	sublicense, assign, rent or otherwise deal in the Tool;
            <br />c)	alter, adapt, merge or translate the Tool for any purpose;
            <br />d)	remove, modify or obscure any copyright, trademark or other proprietary notice in relation to or appearing on or in the Tool;
            <br />e)	make use of the Tool except as expressly provided under these Terms of Use and the GlobalAdvantage Terms of Use;
            <br />f)	submit inaccurate, incomplete, or out-of-date data via the Tool, commit fraud or falsify data in connection with your use of the Tool, or act maliciously against the business interests or reputation of Deloitte or its licensors;
            <br />g)	post, transmit, input, upload, or otherwise provide any data or material that contains any viruses, worms, Trojan horses, logic bombs, time bombs, cancelbots, malware, ransomware, adware, or other harmful computer code or programming routines, including those designed to or that reasonably may disable, damage, impair, interfere with, surreptitiously intercept, or expropriate the Tool or any computers, hardware, software, system, data, or networks;
            <br />h)	engage in data mining or similar data gathering or extraction activities or retrieve data or other content from the Tool for purposes of creating or compiling that content for any purpose;
            <br />i)	not access, use, or copy any portion of the Tool, including any of its content, through the use of indexing agents, spiders, scrapers, bots, web crawlers, or other automated devices or mechanisms.
          </ul>

          <br />1.8	Your permission to access and use the Tool will terminate automatically upon:
          <ul>
            a)	expiration or termination of the licence granted to Deloitte for any reason;
            <br />b)	your ceasing to be authorised for any reason to access and use the Tool; 
            <br />c)	your failure to comply with any of these Terms of Use; or
            <br />d)	notice in writing by Deloitte given at any time.
          </ul>

            <br />1.9	<b>The Tool is provided “as is, as available”</b>. The express warranties and obligations given by Deloitte directly to you in 
            relation to the Tool are for the benefit of you only.  To the fullest extent permitted by law, neither Deloitte, 
            its affiliates and related entities nor any of the partners, principals, members, owners, officers, directors, employees, 
            subcontractors or agents of any of the foregoing nor their successors and assigns (“<b>Deloitte Entities</b>”) give, and you and 
            the Licensee do not receive, any other warranty, obligation or representation of any kind, express or implied, statutory or otherwise.
            Without limitation, Deloitte does not warrant that the Tool or Output will be error free or meet any particular criteria of performance or 
            quality and the Deloitte Entities expressly disclaim and exclude any implied terms of fitness for a particular purpose, 
            non infringement, compatibility, security and accuracy. 

            <br /><br />1.10<b>	It is the responsibility of you and your external users to ensure the Tool is suitable for your needs and to verify that the Output is accurate and fit for any particular purpose.  </b>
            <br /><br />1.11<b>	To the fullest extent permitted by law, the Deloitte Entities will not be liable for any actual, direct, special, indirect, consequential or other loss, damage or expense of any kind arising out of or in connection with the use of the Tool and Output, however caused and whether arising under contract, tort, including without limitation negligence or otherwise. </b>
            <br /><br />1.12	<b>Use of Personal Information.</b> Please review the Privacy Statement for more information regarding the ways in which we collect, use and share personal information.
            <br /><a className="tos_link" href="https://app3.gadeloitte.com/IASTCPortal/dko/main.aspx#PRIVACY" target='_blank' rel='noopener noreferrer'><u>https://app3.gadeloitte.com/IASTCPortal/dko/main.aspx#PRIVACY</u></a>

            <br /> <br />Information on our use of Cookies can be found here:
            <br /><a className="tos_link" href="https://app3.gadeloitte.com/IASTCPortal/dko/main.aspx#HELPCOOKIES" target='_blank' rel='noopener noreferrer'><u>https://app3.gadeloitte.com/IASTCPortal/dko/main.aspx#HELPCOOKIES</u></a>
            
            <br /><br />1.13	Deloitte shall use its own discretion in choosing the third party hosting platform to host the Website and shall have the right to change the third party hosting provider from time to time.
            <br /><br />You shall comply and shall ensure that the Authorised User complies with the hosting provider terms which apply to end users of the Google Cloud Platform (“GCP”) as set out below (as may be updated from time to time) and with the applicable terms of any new hosting provider as notified by Deloitte to you in writing.
            <br /><br />
            <table className="gw__tos-table">
              <tbody>
                <tr>
                  <td className="gw__tos-table-col"><b>GCP Acceptable Use Policy</b></td>
                  <td className="gw__tos-table-content">Compliance with the GCP acceptable use policy available at{" "}<a className="tos_link" href="https://cloud.google.com/terms/aup" target='_blank' rel='noopener noreferrer'><u>https://cloud.google.com/terms/aup</u></a>.</td>
                </tr>
                <tr>
                  <td className="gw__tos-table-col"><b>GCP Service Specific Terms</b></td>
                  <td className="gw__tos-table-content">Compliance with the applicable GCP service specific terms available at{" "} <a className="tos_link" href="https://cloud.google.com/terms/service-terms" target='_blank' rel='noopener noreferrer'><u>https://cloud.google.com/terms/service-terms</u></a>{" "}that apply to Google App Engine, Google Cloud SQL, Cloud Storage and Google Cloud DNS.</td>
                </tr>
                <tr>
                  <td className="gw__tos-table-col"><b>GCP Service Restrictions</b></td>
                  <td className="gw__tos-table-content">
                    Ensure end users of the Website do not:
                    <ul>
                    <br />(a) copy, modify, create a derivative work of, reverse engineer, decompile, translate, disassemble, or otherwise attempt to extract any or all of the source code of the GCP services;
                    <br /><br />(b) use the GCP services for high risk activities (being activities where the use or failure of the GCP service could lead to death, personal injury, or environmental damage); 
                    <br /><br />(c) sublicense, resell, or distribute any or all of the GCP services; 
                    <br /><br />(d) access the GCP services in a manner intended to avoid incurring GCP fees or exceed usage limits or quotas; 
                    <br /><br />(e) unless otherwise set forth in the GCP service specific terms, use the GCP services to operate or enable any telecommunications service or in connection with any application that allows Google end users to place calls or to receive calls from any public switched telephone network;
                    <br /><br />(f) process or store any data that is subject to the International Traffic in Arms Regulations maintained by the United States Department of State; or
                    <br /><br />(g) if you or an Authorised User are (or become) a Covered Entity or Business Associate, as defined in the Health Insurance Portability and Accountability Act of 1996 (as it may be amended from time to time, and any regulations issued under it) (“HIPAA”), you  will not use and will ensure Authorised Users do not use the GCP services for any purpose or in any manner involving Protected Health Information (as defined in HIPAA) unless Google Ireland Limited has given prior written consent to such use.
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td className="gw__tos-table-col"><b>GCP Documentation</b></td>
                  <td className="gw__tos-table-content">
                    Google Ireland Limited may provide documentation for use of the GCP services (as may be updated from time to time) in the form generally made available to its customers for use with the GCP services at{" "}<a className="tos_link" href="https://cloud.google.com/docs" target='_blank' rel='noopener noreferrer'><u>https://cloud.google.com/docs</u></a>.
                    <br /><br />The Documentation may specify restrictions on how the GCP Services may be used by end users, and you will comply and ensure each Authorised User complies with any such restrictions to the extent applicable to you or an Authorised User as an end user of the Website.  Deloitte will provide you with reasonable notice of applicable additional restrictions of which Deloitte becomes aware.
                  </td>
                </tr>
              </tbody>
            </table>

            <br />1.14	If any part of these Terms of Use is held by a court of competent jurisdiction to be unenforceable, the validity of the remainder of these Terms will not be affected.
        </ul>

        <br /><i>Note: Immigration services are provided by the immigration practices of Deloitte Touche Tohmatsu Limited (“DTTL”) member firms or other 
        approved third parties outside the United States or by Berry Appleman & Leiden LLP (“BAL LLP”) in the United States or Farrell LLP in Canada an 
        immigration law firm allied with Deloitte LLP in Canada. 

        <br/><br />BAL LLP provides US immigration services under an alliance with Deloitte LLP.  Deloitte Tax LLP does not provide immigration or 
        legal services and is not a party to the alliance with BAL LLP. </i>
      </div>
    </div>
  );
};

export default TermsOfUseContent;
