export const CountryReducer = (state, action) => {
  switch (action.type) {
  case 'FETCH_COUNTRIES':
    return {
      ...state,
      countries: action.countries,
      destinationCountries: action.destinationCountries,
      fieldsOfStudy: action.fieldsOfStudy,
      jobPositions: action.jobPositions,
      jobLevels: action.jobLevels,
    };
  case 'SELECT_DESTINATION_COUNTRY':
    return {
      ...state,
      selectedDestinationCountry: action.selectedDestinationCountry,
      selectedDestinationCountryExistingVisas: action.selectedDestinationCountryExistingVisas,
      selectedDestinationCountryRequirements: action.selectedDestinationCountryRequirements,
    }
  case 'RESET_COUNTRIES':
    return {
      ...state,
      selectedDestinationCountry: null,
      selectedDestinationCountryRequirements: [],
    }
  default:
    return state;
  }
};

