import React, { useContext } from 'react';
import { Slide } from '@material-ui/core';

import { AssessmentContext } from '../context/AssessmentContext';
import { CountryContext } from '../context/CountryContext';
import STEP_INDEX from '../consts/STEP_INDEX';

const Sidebar = () => {
  const [assessmentState, assessmentDispatch] = useContext(AssessmentContext);
  const [countryState] = useContext(CountryContext);

  const updateIndex = (section, requirementIndexToNavigateTo) => {
    assessmentDispatch({
      type: 'UPDATE_INDEX',
      currentIndex: STEP_INDEX[section],
      currentRequirementIndex: requirementIndexToNavigateTo,
    })
  };

  const checkCurrentIndex = (section, isRequirementIndex, requirementIndex) => {
    // if requirement section
    if (isRequirementIndex) {
      if (assessmentState.currentIndex === STEP_INDEX.requirements && assessmentState.currentRequirementIndex === requirementIndex) return true;

      return false;
    }

    // if default section
    if (assessmentState.currentIndex === STEP_INDEX[section]) return true;

    return false;
  }

  const isRequirementAnswered = (requirementId) => {
    if (assessmentState.requirementResponses.size > 0) {
      let result = assessmentState.requirementResponses.get(requirementId);
      if (result && result.value) {
        return true;
      }
    }

    return false;
  }

  return (
    <ul className="sidebar">
      <div className="sidebar-scroll">

        <Slide timeout={{enter:300}} direction="down" in={true} mountOnEnter unmountOnExit>
          <li className={(checkCurrentIndex('existingVisa') ? 'current' : 'finished done') + ' visa'}>
            <div className="step"> 
              <div className={'vc__icon-hidden ' + (assessmentState.existingVisaDone ? 'transition' : '') }>
                <i className="icon-lock_special lock-icon" />
              </div>
              <h6 className="mt-1">Existing Visa</h6>
            </div>
            <hr />
          </li>
        </Slide>

        <Slide timeout={{enter:300}} direction="down" in={true} mountOnEnter unmountOnExit>
          <li
            onClick={assessmentState.existingVisaDone ? () => updateIndex('sourceCountry', 0) : null}
            className={
              (!assessmentState.existingVisaDone ? 'disabled ': '') +
              (checkCurrentIndex('sourceCountry') ? 'current ' : '') +
              (assessmentState.questionsCompletionStatus.sourceCountry ? 'done' : '')
            }
          >
            <div className="step">
              <div className={'vc__icon-hidden ' + (!!assessmentState.selectedSourceCountry ? 'transition' : '') }>
                <svg aria-labelledby="answer successfully submitted checkmark" xmlns="http://www.w3.org/2000/svg" className="checkmark-icon" width="18" height="18" viewBox="0 0 24 24">
                  <path fill="none" d="M0 0h24v24H0z"/>
                  <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/>
                </svg>
              </div>
              <h6>Source Country</h6>
            </div>
            <hr />
          </li>
        </Slide>

        <Slide timeout={{enter:300}} direction="down" in={true} mountOnEnter unmountOnExit>
          <li
            onClick={assessmentState.existingVisaDone ? () => updateIndex('jobPosition', 0) : null}
            className={
              (!assessmentState.existingVisaDone ? 'disabled ': '') +
              (checkCurrentIndex('jobPosition') ? 'current ' : '') +
              (assessmentState.questionsCompletionStatus.jobPosition ? 'done' : '')
            }
          >
            <div className="step">
              <div className={'vc__icon-hidden ' + (!!assessmentState.selectedJobPosition ? 'transition' : '') }>
                <svg aria-labelledby="answer successfully submitted checkmark" xmlns="http://www.w3.org/2000/svg" className="checkmark-icon" width="18" height="18" viewBox="0 0 24 24">
                  <path fill="none" d="M0 0h24v24H0z"/>
                  <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/>
                </svg>
              </div>
              <h6>Job Family</h6>
            </div>
            <hr />
          </li>
        </Slide>

        <Slide timeout={{enter:300}} direction="down" in={true} mountOnEnter unmountOnExit>
          <li
            onClick={assessmentState.existingVisaDone ? () => updateIndex('citizenship', 0) : null}
            className={
              (!assessmentState.existingVisaDone ? 'disabled ': '') +
              (checkCurrentIndex('citizenship') ? 'current ' : '') +
              (assessmentState.questionsCompletionStatus.citizenship ? 'done' : '')
            }
          >
            <div className="step">
              <div className={'vc__icon-hidden ' + (!!assessmentState.selectedCitizenship ? 'transition' : '') }>
                <svg aria-labelledby="answer successfully submitted checkmark" xmlns="http://www.w3.org/2000/svg" className="checkmark-icon" width="18" height="18" viewBox="0 0 24 24">
                  <path fill="none" d="M0 0h24v24H0z"/>
                  <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/>
                </svg>
              </div>
              <h6>Citizenship</h6>
            </div>
            <hr />
          </li>
        </Slide>

        {
          countryState.selectedDestinationCountryRequirements.map((requirement, index) =>
            <Slide key={index} timeout={{enter:300}} direction="down" in={true} mountOnEnter unmountOnExit>
              <li
                onClick={assessmentState.existingVisaDone ? () => updateIndex('requirements', index) : null}
                className={
                  (!assessmentState.existingVisaDone ? 'disabled ': '') +
                  (checkCurrentIndex('requirements', true, index) ? 'current ' : '') +
                  (!!assessmentState.questionsCompletionStatus.requirements[index] ? 'done' : '')
                }
              >
                <div className="step">
                  <div className={'vc__icon-hidden ' + (isRequirementAnswered(requirement.id) ? 'transition' : '') }>
                    <svg aria-labelledby="answer successfully submitted checkmark" xmlns="http://www.w3.org/2000/svg" className="checkmark-icon" width="18" height="18" viewBox="0 0 24 24">
                      <path fill="none" d="M0 0h24v24H0z"/>
                      <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/>
                    </svg>
                  </div>
                  <h6>{requirement.requirementRevisions[0].name}</h6>
                </div>
                <hr />
              </li>
            </Slide>
            
          )
        }

        <Slide timeout={{enter:300}} direction="down" in={true} mountOnEnter unmountOnExit>
          <li
            className={(!assessmentState.existingVisaDone ? 'disabled ': '') + (checkCurrentIndex('summary') ? 'current ' : '')}
            onClick={assessmentState.existingVisaDone ? () => updateIndex('summary', 0) : null}
          >
            <div className="step">
              <h6 className={checkCurrentIndex('summary') ? 'current ' : ''}>
                  Summary
              </h6>
            </div>
            <hr />
          </li>
        </Slide>

      </div>
    </ul>
  );
};

export default Sidebar;
