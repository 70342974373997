/* eslint-disable no-unused-vars */
import React, { useState, useContext, Fragment } from 'react';
import Popover from '@material-ui/core/Popover';
import { Modal } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import Announcements from '../components/Announcements';
import Logo from '../components/Logo';
import HelpAndSupport from '../screens/HelpAndSupport';

import { logout } from '../services/AuthenticationService';
import { AssessmentContext } from '../context/AssessmentContext';
import { UserContext } from '../context/UserContext';
import { CountryContext } from '../context/CountryContext';
import TermsOfUseContent from './TermsOfUseContent';
import Scrollbars from 'react-custom-scrollbars';
import { plainApi } from "../api";

import { ModalBody, ModalHeader, ModalFooter} from 'reactstrap';

// TODO remove popover dropdown if it is never going to be neeeded. Left commented out for now.

const NavigationBar = () => {
  // Context Variables
  const [assessmentState, assessmentDispatch] = useContext(AssessmentContext);
  const [countryState, countryDispatch] = useContext(CountryContext);
  const [userState, userDispatch] = useContext(UserContext);

  // State Variables
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const api = plainApi();

  let history = useHistory();

  const handleClose = () => {
    setAnchorEl(null);
    setIsOpen(false);
  };

  const handleClick = () => {
    handleLogout();
    // setAnchorEl(event.currentTarget);
    // setIsOpen(true);
  };

  const handleLogout = async() => {
    await logout();
    userDispatch({
      type: 'LOGOUT',
    });
  };

  const resetAssessment = () => {
    history.push('/');
    countryDispatch({ type: 'RESET_COUNTRIES' });
    assessmentDispatch({ type: 'RESET_ASSESSMENT' });
  };

  const toggleHelpAndSupportModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  
  const acceptTOS = () => {
      api.post('tos/accept', userState.token).then(() => userDispatch({
        type: 'TOS',
        tosStatus: false,
      }));
      setIsOpen(false);
      console.log(isOpen);
    }
    
    const declineTOS = () => {
      api.post('tos/decline', userState.token).then(() => userDispatch({
        type: 'TOS',
        tosStatus: true,
      }));
      setIsOpen(false);
      logout();
    }

  return (
    <div className="slideUpDown gw__navbar__wrapper position-fixed">
      {/* Announcements */}
      { userState.isLoggedIn && !userState.isForbidden && <Announcements /> }

      <nav className="navbar">
        <div className="gw__navbar d-flex justify-content-between">
          <div>
            <div className="gw__navbar-logo" onClick={resetAssessment}>
              <Logo />
            </div>
          </div>

          <div className="d-flex justify-content-around align-items-center">
            <p className="gw__sub-items d-flex align-items-center mr-3" onClick={() => setIsModalOpen(true)}>
              <span className="gw__sub-items__icon">
                <i className="icon-life_buoy_special" />
              </span>
              <span>Help &amp; Support</span>
            </p>
            <p className="gw__sub-items d-flex align-items-center mr-3" onClick={() => setIsOpen(true)}>
              <span>Terms of Use</span>
            </p>
            <button className="btn btn-link gw__logout-button" onClick={handleClick}>Sign Out</button>
            {/* <Popover
              open={isOpen}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <div className="gw__avatar-dropdown p-4">
                <a style={{ textDecoration: 'none', color: '#63666A', fontWeight: 300 }} href="/#" onClick={handleLogout}>Logout</a>
              </div>
            </Popover> */}
          </div>
        </div>

        <HelpAndSupport
          isModalOpen={isModalOpen}
          toggleHelpAndSupportModal={toggleHelpAndSupportModal}
        />
          <div>
            <Modal
            className="gw__tos"
            isOpen={isOpen}
            scrollable={true}
            centered
            size="sm"
            style={{ minHeight: '50vh' }}
            >
            <ModalHeader className="pb-0">
                <div className="gw__landing-page-tos-title">Terms of Use</div>
            </ModalHeader>
            <ModalBody className="landing-modal-body"> 
            
                <Scrollbars
                style={{ height: '100%', backgroundColor: '#FFFFFF' }}
                thumbMinSize={150}
                >
                <h5 className="font-weight-extra-bold mt-0">
                    PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE CLICKING THE
                    “I ACCEPT” BUTTON.
                </h5>
                <div>
                  <TermsOfUseContent />
                </div>
                </Scrollbars>
            </ModalBody>
            <ModalFooter>
              <div className="gw__tos-footer px-2 my-2">
                <button className="btn btn-dark mx-1" disabled="" onClick={declineTOS}>
                  DECLINE
                </button>
                <button className="btn btn-primary mx-1" disabled="" onClick={acceptTOS}>
                  I ACCEPT
                </button>
              </div>
            </ModalFooter>
            </Modal>
          </div>      
        </nav>
    </div>
  );
};

export default NavigationBar;
