import React, { useContext, useEffect, useState } from 'react';
import AssessmentQuestion from '../components/AssessmentQuestion';
import { AssessmentContext } from '../context/AssessmentContext';
import { CountryContext } from '../context/CountryContext';

const SelectSourceCountry = () => {
  // Context Variables
  const [assessmentState, assessmentDispatch] = useContext(AssessmentContext);
  const [countryState] = useContext(CountryContext);

  // State Variables
  const [sourceCountryDropdownOptions, setSourceCountryDropdownOptions] = useState([]);

  useEffect(() => {
    buildSourceCountryDropdown();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const buildSourceCountryDropdown = () => {
    let tempSourceCountries = [];

    for (const country of countryState.countries) {
      tempSourceCountries.push({
        label: country.name,
        value: country,
      });
    }

    setSourceCountryDropdownOptions(tempSourceCountries);
  };

  const handleSourceCountrySelection = event => {
    assessmentDispatch({
      type: 'SELECT_SOURCE_COUNTRY',
      selectedSourceCountry: event,
    });
  };

  return (
    <AssessmentQuestion
      question="Where is the candidate currently based?"
      description="Enter the candidate's current location."
      onChangeHandler={handleSourceCountrySelection}
      options={sourceCountryDropdownOptions}
      placeholder="Select country"
      value={assessmentState.selectedSourceCountry ? assessmentState.selectedSourceCountry : null}
    />
  );
};

export default SelectSourceCountry;
