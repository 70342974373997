import { authApi } from '../api';

const api = authApi();

/**
 * Fetch the existing visas for the selected country
 * @param {number} countryId The id of the selected country
 * @param {number} regionId The id of the country's region
 */
const fetchDestinationCountryExistingVisas = async (countryId, regionId) => {
  const destinationCountryExistingVisas = await api.get(`/countries/${countryId}/regions/${regionId}/existing-visas`);

  if (destinationCountryExistingVisas) {
    return destinationCountryExistingVisas.hasOwnProperty('data') ? destinationCountryExistingVisas.data : [];
  }
};

/**
 * Fetch the first node for the selected existing visa
 * @param {number} countryId The id of the selected country
 * @param {number} regionId The id of the country's region
 * @param {number} existingVisaId The id of the selected existing visa
 */
const fetchFirstExistingVisaNode = async (countryId, regionId, existingVisaId) => {
  const existingVisaNodesResponse = await api.get(`/countries/${countryId}/regions/${regionId}/existing-visas/${existingVisaId}`);

  if (existingVisaNodesResponse) {
    return existingVisaNodesResponse.hasOwnProperty('data') ? existingVisaNodesResponse.data : [];
  }
};

/**
 * Fetch the next node based on the selected edge
 * @param {number} countryId The id of the selected country
 * @param {number} regionId The id of the country's region
 * @param {number} existingVisaId The id of the selected existing visa
 * @param {number} edgeId The id of the selected node edge
 */
const fetchNextExistingVisaNode = async (countryId, regionId, existingVisaId, edgeId) => {
  const nextNodeResponse = await api.get(`/countries/${countryId}/regions/${regionId}/existing-visas/${existingVisaId}/select-edge/${edgeId}`);

  if (nextNodeResponse) {
    return nextNodeResponse.hasOwnProperty('data') ? nextNodeResponse.data : [];
  }
};

export {
  fetchDestinationCountryExistingVisas,
  fetchFirstExistingVisaNode,
  fetchNextExistingVisaNode,
};
