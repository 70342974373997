import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Select, { components } from 'react-select';
import Refresh from '@material-ui/icons/Refresh';

import Loading from '../components/Loading';
import { UserContext } from '../context/UserContext';
import { logout } from '../services/AuthenticationService';
import { CountryContext } from '../context/CountryContext';
import { fetchDestinationCountryRequirements } from '../services/CountryService';
import { fetchDestinationCountryExistingVisas } from '../services/ExistingVisaService';
import { AssessmentContext } from '../context/AssessmentContext';
import TermsOfUseModal from '../components/TermsOfUseModal';
import GWLogo from '../images/Go_Work_new_logo.png';
import config from '../config.json'


const LandingPage = (props) => {
  // Context Variables
  const [countryState, countryDispatch] = useContext(CountryContext);
  const [assessmentState, assessmentDispatch] = useContext(AssessmentContext);
  const [userState] = useContext(UserContext);

  // State Variables
  const [destinationCountriesDropdown, setDestinationCountriesDropdown] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (countryState.destinationCountries && countryState.destinationCountries.length >= 0) {
      let tempDestinationCountriesDropdown = [];

      for (const destinationCountry of countryState.destinationCountries) {
        tempDestinationCountriesDropdown.push({
          label: destinationCountry.name,
          value: destinationCountry,
        });
      }

      setDestinationCountriesDropdown(tempDestinationCountriesDropdown);
    }
	  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryState.destinationCountries, userState.isLoggedIn]);

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <i className="icon-simple_arrow_down_special gw__landing-page-select-icon" />
      </components.DropdownIndicator>
    );
  };

  const selectDestinationCountry = async event => {
    let existingVisasResponse = null;
    let requirementsResponse = null;

    // only show loading if the api call is delayed
    setTimeout(() => {
      if (existingVisasResponse === null || requirementsResponse === null) {
        setIsLoading(true);
      }
    }, 300);

    existingVisasResponse = await fetchDestinationCountryExistingVisas(event.value.id, event.value.regions[0].id);
    requirementsResponse = await fetchDestinationCountryRequirements(event.value.id, event.value.regions[0].id);

    let questionsCompletionStatusTemp = { ...assessmentState.questionsCompletionStatus };
    let requirementsStatus = {};
    requirementsResponse.forEach((req, i) => {
      requirementsStatus[i] = false;
    });
    questionsCompletionStatusTemp.requirements = requirementsStatus;

    assessmentDispatch({
      type: 'SET_QUESTIONS_COMPLETION',
      questionsCompletionStatus: questionsCompletionStatusTemp,
    });

    countryDispatch({
      type: 'SELECT_DESTINATION_COUNTRY',
      selectedDestinationCountry: event.value,
      selectedDestinationCountryExistingVisas: existingVisasResponse,
      selectedDestinationCountryRequirements: requirementsResponse,
    });

    setIsLoading(false);
  };

	return (
    <div className="gw__landing-page-container">
    <div className="d-flex justify-content-center align-items-center flex-column h-100">
      
      {/* GoWork Logo */}
      <div className="container pl-0 pr-0">
        <div className="row ml-0 mr-0">
          <div className="col-12 pr-0 pl-0">
            <h1 className="gw__landing-page-title mb-0">
            <img src={GWLogo} alt='Go Work Logo' className='gw__landing-page-logo'/>             
            </h1>
        </div>
      </div>

      {/* GoWork Description */}
      <div className="row ml-0 mr-0">
        <div className="col-12 pr-0 pl-0">
          <div className="gw__landing-page-description-container">
            <p className="gw__landing-page-description">
              Go Work is an indicative immigration assessment tool for recruiters and HR/mobility professionals.
              <br></br>Start your assessment now.
            </p>
          </div>
        </div>
      </div>

      {/* GoWork Login Button or Start Button */}
      <div className="row gw__landing-page-dropdown-container col-12 pr-0 pl-0 ml-0 mr-0">
        {
          userState.isLoggedIn
          ?
            !userState.isForbidden
            ?
	            userState.tosStatus // todo: move below to a TOS component
	            ?
		            <TermsOfUseModal />
		          :
                countryState.destinationCountries.length <= 0
                  ?
                    <div className="d-flex justify-content-center w-100">
                      <Loading />
                    </div>
                  :
                    <Fragment>
                      <div className="d-flex justify-content-center w-100">
                        <Select
                          value={countryState.selectedDestinationCountry && {
                            label: countryState.selectedDestinationCountry.name,
                            value: countryState.selectedDestinationCountry
                          }}
                          placeholder="Where will the role be based?"
                          inputId={'select-destination-country'}
                          className="gw__destination-react-select-container" 
                          classNamePrefix="gw__destination-react-select"
                          components={{DropdownIndicator}}
                          options={destinationCountriesDropdown} 
                          onChange={selectDestinationCountry}
                        />
                      </div>
                      <div className="d-flex justify-content-center w-100">
                          <Link to="/assessment">
                            <button
                              className={`btn btn-primary ${isLoading ? 'btn-loading' : ''}`}
                              disabled={!countryState.selectedDestinationCountry || isLoading}
                            >
                              START
                            </button>
                          </Link>
                      </div>
                    </Fragment>
            :
              <div onClick={() => logout()}>
                <div className="d-flex justify-content-center w-100">
                  <div className="d-flex gw__retry-login">
                    <Refresh /><div>TRY AGAIN</div>
                  </div>
                </div>
                <div className="d-flex justify-content-center w-100">
                  <div className="gw__retry-login">
                    Restricted email domain, access forbidden. Please contact administrator.
                  </div>
                </div>
              </div>
          :
            <>
              {
                userState.hasCheckedUserStatus
                ?
                  <div className="d-flex justify-content-center w-100">
                    <h6>Please go to the <a className="text-primary" href={config.landingPageLoginHref} target="blank">{config.landingPageLoginText}</a> application to login.</h6>
                  </div>
                :
                  <div className="d-flex justify-content-center w-100">
                    <Loading />
                  </div>
              }
            </>
        }
        </div>
    </div>
    </div>
  </div>
  );
};

export default LandingPage;
