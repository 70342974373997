import React, { useContext, useEffect, useState } from 'react';
import AssessmentQuestion from '../components/AssessmentQuestion';
import { AssessmentContext } from '../context/AssessmentContext';
import { CountryContext } from '../context/CountryContext';

const SelectCitizenship = () => {
  // Context Variables
  const [assessmentState, assessmentDispatch] = useContext(AssessmentContext);
  const [countryState] = useContext(CountryContext);

  // State Variables
  const [citizenshipDropdownOptions, setCitizenshipDropdownOptions] = useState([]);

  useEffect(() => {
    buildCitizenshipDropdown();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const buildCitizenshipDropdown = () => {
    let tempCitizenships = [{
      label: 'Not disclosed',
      value: {
        country: {
          id: 0,
          name: 'Not disclosed',
        },
        region: {
          id: 0,
          name: 'Not disclosed',
        }
      },
    }];

    for (const country of countryState.countries) {
      if (country.regions[0]) {
        tempCitizenships.push({
          label: country.name,
          value: {
            country: {
              id: country.id,
              name: country.name,
            },
            region: {
              id: country.regions[0].id,
              name: country.regions[0].name,
            },
          },
        });
      }
    }

    setCitizenshipDropdownOptions(tempCitizenships);
  };

  const handleCitizenshipSelection = event => {
    assessmentDispatch({
      type: 'SELECT_CITIZENSHIP',
      selectedCitizenship: event,
    });
  };

  return (
    <AssessmentQuestion
      question="Has the candidate disclosed their citizenship?"
      description="If the candidate has disclosed their country of citizenship with you please enter it below, otherwise please proceed without. Citizenship will impact visa eligibility so if you don’t have it now please re-run the assessment when it is available."
      onChangeHandler={handleCitizenshipSelection}
      options={citizenshipDropdownOptions}
      placeholder="Select country of citizenship"
      value={assessmentState.selectedCitizenship ? assessmentState.selectedCitizenship : null}
    />
  );
};

export default SelectCitizenship;
