import React from 'react';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';

const ResultsPermit = ({ workPermits }) => {

  const goToAlternatives = () => {
    document.getElementById('alternatives').scrollIntoView({behavior: "smooth"});
    
    setTimeout(() => {
      document.getElementById('alternatives').classList.add('highlight');
    }, 400);
    setTimeout(() => {
      document.getElementById('alternatives').classList.remove('highlight');
    }, 1100);
  }
  return (
    workPermits.length > 0 &&
    <>
      <div className="row">
        <div className="col-12">
          <h1 className="font-weight-light gw__results__permit-title">Recommended Permit Type</h1>
          {
            workPermits.length > 1 &&
            <p>
              Alternative permit types are available.
              <span className="gw__results__multi-permit-link" onClick={goToAlternatives}>
                View alternatives
                <DoubleArrowIcon />
              </span>
            </p>
          }
        </div>
      </div>
      <hr/>
      <div className="row d-flex justify-content-between my-5">
        <div className="col-6">
          <p className="gw__results-permit-heading">
            <i className="icon-briefcase_special gw__results-icon" />
            Permit Type
          </p>
          <p className="gw__results-permit-text">{workPermits[0].name}</p>
          <p className="gw__results-permit-description">{workPermits[0].description}</p>
        </div>
        <div className="col-12 col-md-6 d-flex justify-content-start justify-content-md-around">
          <div className="pr-5 pr-md-0">
            <p className="gw__results-permit-heading">
              <i className="icon-clock_special gw__results-icon" />
              Validity
            </p>
            {
              workPermits[0].validity < 1
              ? workPermits[0].validity === 0
                ? <p className="gw__results-permit-text">Indefinite</p>
                : <p className="gw__results-permit-text">N/A</p>
              : <h2 className="gw__results-permit-text">{workPermits[0].validity} years</h2>
            }
          </div>
          <div>
            <p className="gw__results-permit-heading">
              <i className="icon-house_special gw__results-icon" />
              Permanent Residency
            </p>
            <h2 className="gw__results-permit-text">
              {
                workPermits[0].permanentResidency < 2
                ? workPermits[0].permanentResidency === 0
                  ? 'Not Permitted'
                  : 'Permitted'
                : 'N/A'
              }
            </h2>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResultsPermit;
