import React from 'react';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import { withStyles } from '@material-ui/core/styles';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import {convertFromRaw} from 'draft-js';
import {stateToHTML} from 'draft-js-export-html';
import ResultsAllPermitOptions from '../components/ResultsAllPermitOptions';

const ExpansionPanel = withStyles({
  root: {
    border: 'none',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 'none',
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'white',
    borderBottom: '1px solid #d1d1d1',
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
      borderBottom: '1px solid white',
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {

  },
})(MuiExpansionPanelSummary);


const ResultsSections = ({ workPermits }) => {
  function convertContent(content) {
    if(content) {
    const contentState = convertFromRaw(JSON.parse(content));
    let html = stateToHTML(contentState);
    return html;
    }
  }

  function filterPermitOptions(workPermits) {
    const filteredWorkPermitList = workPermits.filter(wp => wp.name.toLowerCase().trim() !== 's pass or employment pass' && !wp.isManuallyAssessed);
    return filteredWorkPermitList;
  }
 
  return (
    workPermits.length > 0 &&
    <div className="row my-5">
      {
        workPermits[0].recruiterOutput.sections.length > 0
        ?
          workPermits[0].recruiterOutput.sections.map((section,index) => (       
            <div key={index} className="col-12 col-md-10">
              <ExpansionPanel defaultExpanded={false}>
                <ExpansionPanelSummary>
                  <div className="d-flex justify-content-between align-items-center w-100">
                    <h6 className={`gw__results-sections-title` + (index === 0 && section.title.trim().toLowerCase().includes('important') 
                      ? ' gw__results-sections-title-highlighted' : '')}>
                      {section.title.trim().charAt(0).toUpperCase() + section.title.trim().slice(1).toLowerCase()}
                    </h6>
                    <i className="icon-simple_arrow_down_special gw__results-sections-icons" />
                  </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div className="content gw__results-sections-content" dangerouslySetInnerHTML={{__html: convertContent(section.content)}} />
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </div>
          ))
        :
          <div className="col-12 col-md-10">
            <p>No sections configured for this work permit.</p>
          </div>
      }
      {
        filterPermitOptions(workPermits).length > 1 &&
        <div className="col-12 col-md-10" id="alternatives">
          <ExpansionPanel defaultExpanded={false}>
            <ExpansionPanelSummary>
              <div className="d-flex justify-content-between align-items-center w-100">
                <h6 className="gw__results-sections-title">
                  Alternative Permit Options
                </h6>
                <i className="icon-simple_arrow_down_special gw__results-sections-icons" />
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div className="w-100">
                <ResultsAllPermitOptions workPermits={filterPermitOptions(workPermits)} />
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
      }
    </div>
  );
}

export default ResultsSections;
