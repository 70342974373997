import React, { useContext } from 'react';
import { AssessmentContext } from '../context/AssessmentContext';
import { CountryContext } from '../context/CountryContext';


const ResultsWorkPermitTable = ({ineligiblePermit}) => {

  const [assessmentState] = useContext(AssessmentContext);
  const [countryState] = useContext(CountryContext);

  const getFieldOfStudyResponse = (response, fieldOfStudyOptions) => {
    let fieldOfStudyResponse = null;
    response.forEach((val) => {
      if (val.type === 'fieldOfStudy') fieldOfStudyResponse = val;
    });

    let isSelectedFieldOfStudyFound = fieldOfStudyOptions.filter(fos => fos.id === fieldOfStudyResponse.value.value)[0]; 

    if (isSelectedFieldOfStudyFound) {

      return isSelectedFieldOfStudyFound.name;
    }
    return 'N/A';
  }

  const getJobLevelResponse = (response) => {
    let res = null;
    response.forEach((val) => {
      if (val.type === 'jobLevel') res = val;
    });

    if (res) {
      return res.label;
    }
    return 'N/A';
  }

  return (
    <div className="w-100">
      {
        <div className="row">
          <div className="col-12">
            <table className="table text-left">
              <thead>
                <tr>
                  <th scope="col">Input</th>
                  <th scope="col">RESPONSE</th>
                  <th scope="col" className="text-center" style={{width: '100px'}}>Criteria met?</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="font-weight-extra-bold">Destination Country</td>
                  <td>{countryState.selectedDestinationCountry.name}</td>
                  <td className="text-center p-0">
                    <i className="icon-checkbox_tick_special text-primary" style={{fontSize: '35px'}} />
                  </td>
                </tr>
                <tr>
                  <td className="font-weight-extra-bold">Source Country</td>
                  <td>{assessmentState.selectedSourceCountry.value.name}</td>
                  <td className="text-center p-0">
                    {
                      ineligiblePermit.requirementsMap.filter(req => req.requirementId === 'Source Country')[0]?.isMatching ?
                      <i className="icon-checkbox_tick_special text-primary" style={{fontSize: '35px'}} /> :
                      <i className="icon-cross_special text-danger" style={{fontSize: '28px'}} />
                    }
                  </td>
                </tr>
                <tr>
                  <td className="font-weight-extra-bold">Job Family</td>
                  <td>{assessmentState.selectedJobPosition.value.jobFamily}</td>
                  <td className="text-center p-0">
                    {
                      ineligiblePermit.requirementsMap.filter(req => req.requirementId === 'Job Code')[0]?.isMatching ?
                      <i className="icon-checkbox_tick_special text-primary" style={{fontSize: '35px'}} /> :
                      <i className="icon-cross_special text-danger" style={{fontSize: '28px'}} />
                    }
                  </td>
                </tr>
                <tr>
                  <td className="font-weight-extra-bold">Citizenship</td>
                  <td>{assessmentState.selectedCitizenship.value.country.name}</td>
                  <td className="text-center p-0">
                    {
                      ineligiblePermit.requirementsMap.filter(req => req.requirementId === 'Citizenship')[0]?.isMatching ?
                        <i className="icon-checkbox_tick_special text-primary" style={{fontSize: '35px'}} /> :
                        <i className="icon-cross_special text-danger" style={{fontSize: '28px'}} />
                    }
                  </td>
                </tr>

                {
                  countryState.selectedDestinationCountryRequirements.map((requirement, index) => (
                    <React.Fragment key={requirement.id + index}>
                      {
                        (requirement.type !== 'fieldOfStudy' && requirement.type !== 'jobLevel'&& requirement.type !== 'clientSpecificQuestion') && (
                          <tr>
                            <td className="font-weight-extra-bold">{requirement.requirementRevisions[0].name}</td>
                            <td>{assessmentState.requirementResponses.get(requirement.id).label}</td>
                            <td className="text-center p-0">
                              {
                                // eslint-disable-next-line eqeqeq
                                ineligiblePermit.requirementsMap.filter(req => req.requirementId == requirement.id)[0]?.isMatching ?
                                  <i className="icon-checkbox_tick_special text-primary" style={{fontSize: '35px'}} /> :
                                  <i className="icon-cross_special text-danger" style={{fontSize: '28px'}} />
                              }
                            </td>
                          </tr>
                        )
                      }
                    </React.Fragment>
                  ))
                }

                {
                  countryState.selectedDestinationCountryRequirements.filter(requirement => requirement.type === 'fieldOfStudy')[0] && (
                    <tr>
                      <td className="font-weight-extra-bold">Field of Study</td>
                      <td>{getFieldOfStudyResponse(assessmentState.requirementResponses, countryState.fieldsOfStudy)}</td>
                      <td className="text-center p-0">
                        {
                          ineligiblePermit.requirementsMap.filter(req => req.requirementId === 'FieldOfStudy')[0]?.isMatching ?
                            <i className="icon-checkbox_tick_special text-primary" style={{fontSize: '35px'}} /> :
                            <i className="icon-cross_special text-danger" style={{fontSize: '28px'}} />
                        }
                      </td>
                    </tr>
                  )
                }

                {
                  countryState.selectedDestinationCountryRequirements.filter(requirement => requirement.type === 'jobLevel')[0] && (
                    <tr>
                      <td className="font-weight-extra-bold">Job Level</td>
                      <td>{getJobLevelResponse(assessmentState.requirementResponses)}</td>
                      <td className="text-center p-0">
                        {
                          ineligiblePermit.requirementsMap.filter(req => req.requirementId === 'jobLevel')[0]?.isMatching ?
                            <i className="icon-checkbox_tick_special text-primary" style={{fontSize: '35px'}} /> :
                            <i className="icon-cross_special text-danger" style={{fontSize: '28px'}} />
                        }
                      </td>
                    </tr>
                  )
                }
              </tbody>
            </table>
          </div>
        </div>
      }
    </div>
  )
}

export default ResultsWorkPermitTable



