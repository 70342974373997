import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { AssessmentContext } from '../context/AssessmentContext';
import { CountryContext } from '../context/CountryContext';
import ExistingVisa from '../components/ExistingVisa';
import SelectCitizenship from './SelectCitizenship';
import SelectJobPosition from './SelectJobPosition';
import SelectSourceCountry from './SelectSourceCountry';
import Sidebar from '../components/Sidebar';
import Summary from './Summary';
import STEP_INDEX from '../consts/STEP_INDEX';
import STEP_INDEX_MAP from '../consts/STEP_INDEX_MAP';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import AssessmentQuestion from '../components/AssessmentQuestion';

const Assessment = () => {
  // Context Variables
  const [assessmentState, assessmentDispatch] = useContext(AssessmentContext);
  const [countryState, countryDispatch] = useContext(CountryContext);
  
  // State Variables
  const [redirectTo, setRedirectTo] = useState('');
  const [refresh, setRefresh] = useState(false);
  const [requirementsIndex, setRequirementsIndex] = useState(null);
  const [requirementsMap, setRequirementsMap] = useState(null);
  const [requirements, setRequirements] = useState(null);
  const [inTransition, setInTransition] = useState(false)
  const [isHasExistingVisaSelected, setIsHasExistingVisaSelected] = useState(false);
  const [isExistingVisaModalOpen, setIsExistingVisaModalOpen] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(Object.keys(STEP_INDEX).filter(key => STEP_INDEX[key] === assessmentState.currentIndex)[0]);
  
  useEffect(() => {
    if (!countryState.selectedDestinationCountry) {
      setRedirectTo('/');
    } else {
      assessmentDispatch({
        type: 'LOG_ASSESSMENT_START_TIME',
        startTime: Date.now(),
      });
      buildRequirementsMap();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryState.selectedDestinationCountry]);

  useEffect(() => {
    setCurrentQuestion(Object.keys(STEP_INDEX).filter(key => STEP_INDEX[key] === assessmentState.currentIndex)[0]);
  }, [assessmentState.currentIndex, assessmentState.currentRequirementIndex]);
  const buildRequirementsMap = () => {
    const tempRequirementsIndex = [];
    const tempRequirementsMap = new Map();
    const tempRequirements = [];

    for (const requirement of countryState.selectedDestinationCountryRequirements) {
      tempRequirementsIndex.push(requirement.id);
      const requirementObject = {
        requirement,
        requirementValuesDropdown: [],
      };

      // Check the type (for field of study)
      if (requirement.type === 'fieldOfStudy') {
        for (const fieldOfStudy of countryState.fieldsOfStudy) {
          requirementObject.requirementValuesDropdown.push({
            label: fieldOfStudy.name + ' - ' + fieldOfStudy.description,
            value: fieldOfStudy,
            type: 'fieldOfStudy',
          });
        }
      } else if (requirement.type === 'jobLevel') {
	      for (const jobLevel of countryState.jobLevels) {
		      requirementObject.requirementValuesDropdown.push({
			      label: jobLevel.name,
			      value: jobLevel,
			      type: 'jobLevel',
		      });
	      }
      } else if (requirement.type === 'salaryThreshold') {
        for (const salaryRange of requirement.values) {
          requirementObject.requirementValuesDropdown.push({
            label: salaryRange.value,
            value: salaryRange.min.toString(),
            type: 'salaryThreshold',
          });
        }
      } else if (requirement.values || requirement.type !== 'clientSpecificQuestion') {
        // Build the values dropdown
        for (const value of requirement.values) {
          // Filter out the Not Required values TODO: MOVE THIS TO THE BACKEND
          if (value.requirementValueRevisions[0].value !== 'Not Required') {
            requirementObject.requirementValuesDropdown.push({
              label: value.requirementValueRevisions[0].value,
              value: value,
            });
          }
        }
      }

      tempRequirementsMap.set(requirement.id, requirementObject);
      tempRequirements.push(requirementObject);
    }
    setRequirementsIndex(tempRequirementsIndex);
    setRequirementsMap(tempRequirementsMap);
    setRequirements(tempRequirements);
  };
  let requirementResponsesTemp = new Map(assessmentState.requirementResponses);

  const handleRequiremenOptionSelect = selectedValue => {

    requirementResponsesTemp.set(requirementsMap.get(requirementsIndex[assessmentState.currentRequirementIndex]).requirement.id, selectedValue);

    assessmentDispatch({
      type: 'SELECT_REQUIREMENT_OPTION',
      requirementResponses: requirementResponsesTemp,
    });

    setRefresh(!refresh);
  };

  const handleClientSpecificQuestionAnswer = (selectedValue) => {
    assessmentDispatch({
      type: 'SET_CANDIDATE_ID',
      clientSpecificQuestion: selectedValue,
    });
    let tempSelectedValue = {
      label: selectedValue,
      value: selectedValue,
      type: 'clientSpecificQuestion',
  };
    requirementResponsesTemp.set(requirementsMap.get(requirementsIndex[assessmentState.currentRequirementIndex]).requirement.id, tempSelectedValue);

    assessmentDispatch({
      type: 'SELECT_REQUIREMENT_OPTION',
      requirementResponses: requirementResponsesTemp,
    });
    setRefresh(!refresh);
  };
    
  const getTransitionState = () => {
    if (assessmentState.currentIndex === STEP_INDEX.requirements) {

      return assessmentState.previousRequirementIndex > assessmentState.currentRequirementIndex ? 'gw__question--back' : 'gw__question';
    }

    return assessmentState.previousIndex > assessmentState.currentIndex ? 'gw__question--back' : 'gw__question';
  }

  const isQuestionAnswered = (isRequirement) => {

    if (isRequirement) {
      if (assessmentState.requirementResponses.size > 0) {
        let result = assessmentState.requirementResponses.get(requirementsIndex[assessmentState.currentRequirementIndex]);
        if (result && result.value) {

          return true;
        }
      }

      return false;
    }

    return !!assessmentState[STEP_INDEX_MAP[currentQuestion]];
  }

  const updateQuestionStatus = (isRequirement) => {
    const questionsStatus = { ...assessmentState.questionsCompletionStatus };

    if (isQuestionAnswered(isRequirement)) {
      if (isRequirement) {
        questionsStatus.requirements[assessmentState.currentRequirementIndex.toString()] = true;
      } else {
        questionsStatus[currentQuestion] = true;
      }
      assessmentDispatch({
        type: 'UPDATE_QUESTION_COMPLETION',
        questionsCompletionStatus: questionsStatus,
      });
    }
  }

  const continueButtonHandler = () => {
    if(assessmentState.currentIndex === STEP_INDEX.existingVisa) {
      // Next on existing visa
      if (isHasExistingVisaSelected) {
        setIsExistingVisaModalOpen(true);
      } else {
        assessmentDispatch({ type: 'SET_EXISTING_VISA_DONE' });
      }
    }
    else if (assessmentState.currentIndex === STEP_INDEX.requirements && assessmentState.currentRequirementIndex < countryState.selectedDestinationCountryRequirements.length - 1) {
      // Next on requirements question if not last question
      updateQuestionStatus(true);
      assessmentDispatch({ type: 'INCREMENT_REQUIREMENT_INDEX' });

    } else if (assessmentState.currentIndex === STEP_INDEX.requirements) {
      // Next on requirements question if last one
      updateQuestionStatus(true);
      assessmentDispatch({ type: 'INCREMENT_INDEX' });

    } else {
      updateQuestionStatus(false);
      assessmentDispatch({ type: 'INCREMENT_INDEX' });
    }
  };

  const backButtonHandler = () => {
    // Check if the user is in the requirements section
    if (assessmentState.currentIndex === STEP_INDEX.requirements && assessmentState.currentRequirementIndex > 0) {
      assessmentDispatch({ type: 'DECREMENT_REQUIREMENT_INDEX' });
    } else {
      assessmentDispatch({ type: 'DECREMENT_INDEX' });
    }
  }

  const resetAssessment = () => {
    countryDispatch({ type: 'RESET_COUNTRIES' });
    assessmentDispatch({ type: 'RESET_ASSESSMENT' });
  }

  return (
    redirectTo
    ? <Redirect to={redirectTo} />
    : <Fragment>
        <div className="d-flex flex-column h-100 gw__assessment__wrapper">
          <div className="col-12 col-md-9 px-0 py-5 d-flex flex-column justify-content-center align-items-center gw__assessment">
            {/* summary component does not use transition */}
            {
              assessmentState.currentIndex === STEP_INDEX.summary && <Summary />
            }
            {
              assessmentState.currentIndex !== STEP_INDEX.summary && (
              <div className={`gw__question__wrapper ${inTransition ? 'overflow-hidden' : 'overflow-visible'}`}>
                <TransitionGroup
                  component={null}
                  childFactory={child => React.cloneElement(child, { classNames: getTransitionState() })}
                >
                  <CSSTransition 
                    key={assessmentState.currentIndex + assessmentState.currentRequirementIndex}
                    timeout={500}
                    onEnter={() => setInTransition(true)}
                    onEntered={() => setInTransition(false)}
                    unmountOnExit
                  >
                    <div key={assessmentState.currentIndex + assessmentState.currentRequirementIndex}>
                      { 
                        assessmentState.currentIndex === STEP_INDEX.existingVisa &&
                        <ExistingVisa
                          isModalOpen={isExistingVisaModalOpen}
                          setIsModalOpen={setIsExistingVisaModalOpen}
                          hasExistingVisa={isHasExistingVisaSelected}
                          setHasExistingVisa={setIsHasExistingVisaSelected}
                        />
                      }
                      { assessmentState.currentIndex === STEP_INDEX.sourceCountry && <SelectSourceCountry /> }
                      { assessmentState.currentIndex === STEP_INDEX.jobPosition && <SelectJobPosition /> }
                      { assessmentState.currentIndex === STEP_INDEX.citizenship && <SelectCitizenship /> }
                      {
                        assessmentState.currentIndex === STEP_INDEX.requirements &&
                        requirements &&
                        requirements.length > 0 &&
                        <AssessmentQuestion
                          question={
                            requirements[assessmentState.currentRequirementIndex].requirement.requirementRevisionsCompany[0]?.question ||
                            requirements[assessmentState.currentRequirementIndex].requirement.requirementRevisions[0].question
                          }
                          description={
                            requirements[assessmentState.currentRequirementIndex].requirement.requirementRevisionsCompany[0]?.description ||
                            requirements[assessmentState.currentRequirementIndex].requirement.requirementRevisions[0].description
                          }
                          onChangeHandler={handleRequiremenOptionSelect}
                          options={requirements[assessmentState.currentRequirementIndex].requirementValuesDropdown}
                          placeholder={`Select ${requirements[assessmentState.currentRequirementIndex].requirement.requirementRevisions[0].name}`}
                          type={requirements[assessmentState.currentRequirementIndex].requirement.type}
                          value={assessmentState.requirementResponses.get(requirementsIndex[assessmentState.currentRequirementIndex])
                            ? assessmentState.requirementResponses.get(requirementsIndex[assessmentState.currentRequirementIndex])
                            : null
                          }
                          handleClientSpecificQuestionAnswer={handleClientSpecificQuestionAnswer}
                                                   
                        />
                      }
                    </div>
                  </CSSTransition>
                </TransitionGroup>
              </div>
              )
            }
            {/* BUTTONS */}
            {
              assessmentState.currentIndex !== STEP_INDEX.summary && (
                <div className="gw__button-group">
                  {
                    assessmentState.currentIndex >= 1
                    ? <button className="btn btn-link pl-0" aria-label="Back button" onClick={backButtonHandler}>
                        BACK
                      </button>
                    : <button className="btn btn-link pl-0" aria-label="End assessment button" onClick={resetAssessment}>
                        END ASSESSMENT
                      </button>
                  }

                  <button className="btn btn-primary" aria-label="Continue to next question" type="button" onClick={continueButtonHandler} >
                    NEXT
                  </button>
                </div>
              )
            }
          </div>
        </div>
        <Sidebar />
      </Fragment>
  );
};

export default Assessment;
