export const AssessmentReducer = (state, action) => {
  switch (action.type) {
    case 'DECREMENT_INDEX':
      return {
        ...state,
        previousIndex: state.currentIndex,
        currentIndex: state.currentIndex - 1,
      };
    case 'DECREMENT_REQUIREMENT_INDEX':
      return {
        ...state,
        previousRequirementIndex: state.currentRequirementIndex,
        currentRequirementIndex: state.currentRequirementIndex - 1,
      };
    case 'INCREMENT_INDEX':
      return {
        ...state,
        previousRequirementIndex: 0,
        previousIndex: state.currentIndex,
        currentIndex: state.currentIndex + 1,
      };
    case 'INCREMENT_EXISTING_VISA_INDEX':
      return {
        ...state,
        currentExistingVisaIndex: state.currentExistingVisaIndex + 1,
      };
    case 'INCREMENT_REQUIREMENT_INDEX':
      return {
        ...state,
        previousRequirementIndex: state.currentRequirementIndex,
        currentRequirementIndex: state.currentRequirementIndex + 1,
      };
    case 'LOG_ASSESSMENT_START_TIME':
      return {
        ...state,
        startTime: action.startTime,
      };
    case 'RESET_ASSESSMENT':
      return {
        ...state,
        currentExistingVisaIndex: 0,
        previousIndex: 0,
        currentIndex: 0,
        previousRequirementIndex: 0,
        currentRequirementIndex: 0,
        eligibleWorkPermits: [],
        existingVisaDone: false,
        existingVisaResult: null,
        requirementResponses: new Map(),
        selectedExistingVisa: null,
        selectedSourceCountry: null,
        selectedJobPosition: null,
        selectedCitizenship: null,
        selectedFieldOfStudy: null,
        clientSpecificQuestion: '',
        startTime: null,
        questionsCompletionStatus: {
          selectedCitizenship: false,
          selectedExistingVisa: false,
          selectedFieldOfStudy: false,
          selectedJobPosition: false,
          selectedSourceCountry: false,
          requirements: {}
        },
      };
    case 'RESET_EXISTING_VISA':
      return {
        ...state,
        currentExistingVisaIndex: 0,
        existingVisaResult: null,
        selectedExistingVisa: null,
      };
    case 'SET_EXISTING_VISA_DONE':
      return {
        ...state,
        existingVisaDone: true,
        currentIndex: 1,
        currentRequirementIndex: 0,
      }; 
    case 'SET_EXISTING_VISA_RESULT':
      return {
        ...state,
        existingVisaDone: true,
        existingVisaResult: {
          title: action.existingVisaResult.title,
          icon: action.existingVisaResult.icon,
          subtext: action.existingVisaResult.subtext,
          isContinuingToAssessment: action.existingVisaResult.isContinuingToAssessment,
        },
        currentExistingVisaIndex: action.currentExistingVisaIndex,
      };
    case 'SELECT_EXISTING_VISA':
      return {
        ...state,
        selectedExistingVisa: action.selectedExistingVisa,
      };
    case 'SELECT_SOURCE_COUNTRY':
      return {
        ...state,
        selectedSourceCountry: action.selectedSourceCountry,
      };
    case 'SELECT_JOB_POSITION':
      return {
        ...state,
        selectedJobPosition: action.selectedJobPosition,
      };
    case 'SELECT_CITIZENSHIP':
      return {
        ...state,
        selectedCitizenship: action.selectedCitizenship
      };
    case 'SELECT_FIELD_OF_STUDY':
      return {
        ...state,
        selectedFieldOfStudy: action.selectedFieldOfStudy,
      };
    case 'SET_ELIGIBLE_WORK_PERMITS':
      return {
        ...state,
        eligibleWorkPermits: action.eligibleWorkPermits,
        ineligibleWorkPermits: action.ineligibleWorkPermits,
      };
    case 'UPDATE_INDEX':
      return {
        ...state,
        previousIndex: state.currentIndex,
        currentIndex: action.currentIndex,
        previousRequirementIndex: state.currentRequirementIndex,
        currentRequirementIndex: action.currentRequirementIndex,
      };
    case 'SELECT_REQUIREMENT_OPTION':
      return {
        ...state,
        requirementResponses: action.requirementResponses,
      };
    case 'SET_QUESTIONS_COMPLETION':
      return {
        ...state,
        questionsCompletionStatus: action.questionsCompletionStatus,
      };
    case 'UPDATE_QUESTION_COMPLETION':
      return {
        ...state,
        questionsCompletionStatus: action.questionsCompletionStatus,
      };
    case 'SET_CANDIDATE_ID' :
      return {
        ...state,
        clientSpecificQuestion : action.clientSpecificQuestion,
      }
    default:
      return state;
    }
};
