import axios from 'axios';

export function plainApi() {
  return axios.create({
    baseURL: process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_BASE_URL : '/api',
    withCredentials: true,
    'Cache-Control': 'no-cache',
  });
}

export function authApi() {
  const api = plainApi();

  api.interceptors.response.use(
    response => response,
    error => {
      if (error && error.response && (error.response.status === 403 || error.response.status === 401)) {
        window.location.replace('/#/expired');
      }
    }
  );

  return api;
}
