import React, { useContext, useEffect } from 'react';
import StarBorderOutlinedIcon from '@material-ui/icons/StarBorderOutlined';
import { useState } from 'react';
import { AssessmentContext } from '../context/AssessmentContext';

const ResultsAllPermitOptions = ({ workPermits }) => {
  const [assessmentState] = useContext(AssessmentContext);
	const [isPremium, setIsPremium] = useState(false);
  useEffect(()=>{
    if(assessmentState.onlyUsePremiumTimes){
      setIsPremium(true);
    }
  }, [assessmentState.onlyUsePremiumTimes]);
  return (workPermits.length > 1 &&
    <div className="row">
      <div className="col-12">
        <table className="table text-left">
          <thead>
            <tr>
              <th scope="col" className="text-center">RANK</th>
              <th scope="col">TYPE</th>
              <th scope="col">VALIDITY</th>
              <th scope="col">PERM. RES.</th>
              <th scope="col">EST. PROC. TIME</th>
            </tr>
          </thead>
          <tbody>
            {
              workPermits.map((wp, index) => (
                <tr key={wp.name} className={index === 0 ? 'bg-primary' : ''}>
                  <th scope="row" className="text-center font-weight-extra-bold">
                  {
                    index === 0 ? <StarBorderOutlinedIcon /> : (index + 1)
                  }
                  </th>
                  <td className="font-weight-extra-bold">{wp.name}</td>
                  <td>{
                  !!wp.validity 
                    ? wp.validity > 0
                      ?`${wp.validity} Years` 
                      : 'N/A'
                  : 'N/A'
                  }</td>
                  <td>{wp.permanentResidency ? 'Permitted' : 'Not permitted'}</td>
                  <td>{
                    isPremium
                    ? wp.totalPremiumProcessingTime
                    : wp.totalProcessingTime
                  } Weeks</td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    </div>
  )
};

export default ResultsAllPermitOptions;
