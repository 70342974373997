import { authApi } from '../api';

const api = authApi();

/**
 * Fetch the client's job positions
 */
const fetchJobPositions = async () => {
  const jobPositionResponse = await api.get('/job-positions');

  if (jobPositionResponse) {
    return jobPositionResponse.hasOwnProperty('data') ? jobPositionResponse.data : [];
  }
}

/**
 * Get the company job levels to be displayed.
 */
const fetchJobLevels = async () => {
  const jobLevelResponse = await api.get('/job-levels');

  if (jobLevelResponse) {
    return jobLevelResponse.hasOwnProperty('data') ? jobLevelResponse.data  : [];
  }
}

export {
  fetchJobPositions,
  fetchJobLevels,
};
