import { authApi } from '../api';

const api = authApi();

const fetchStartDates = async (id, startDateObject) => {
  const startDatesResponse = await api.post(`work-permits/${id}/recruiter-output/calculate-start-dates`, startDateObject);

  if (startDatesResponse) {
    return startDatesResponse.hasOwnProperty('data') ? startDatesResponse.data : [];
  }
};

/**
 * Submit the candidate's responses and retrieve a work permit, if eligiible
 * @param {number} regionId The id of the destination region
 * @param {object} candidateResponses The answers the candidate has submitted
 */
const submitCandidateInformation = async (regionId, candidateResponses, requirementsMap) => {
  let requirementResponses = [];

  for (const [key, value] of candidateResponses.responses) {
    // TODO - NEEDS REFACTORING IN THE BACKEND TO IMPROVE THIS SECTION
    // Add in an additional check for the field of study
    if (value.hasOwnProperty('type') && value.type === 'fieldOfStudy') {
      requirementResponses.push({
        requirementId: key,
        requirementName: 'Field of Study',
        value: {
          value: value.value.id,
          type: 'fieldOfStudy',
          title: value.value.name,
        },
      });
    } else if (value.hasOwnProperty('type') && value.type === 'jobLevel') {
			requirementResponses.push({
        requirementId: key,
        requirementName: 'Job Level',
				value: {
          companyJobLevelId: value.value.id,
          type: 'jobLevel',
          name: value.value.value,
        },
			});
    }  else if (value.hasOwnProperty('type') && value.type === 'clientSpecificQuestion') {
			requirementResponses.push({
        requirementId: key,
        requirementName: 'Candidate ID',
				value: {
          type: 'clientSpecificQuestion',
          label: value.value,
          name: value.value,
        },
			});
    }
    else {
      let requirementValue = 0;
      if (value.hasOwnProperty('type') && value.type === 'salaryThreshold') {
        requirementValue = value;
      } 
      else {
        requirementValue = value.value.requirementValueRevisions[0];
      }
      requirementResponses.push({
        requirementId: key,
        requirementName: requirementsMap.get(key).requirement.requirementRevisions[0].name,
        value: requirementValue,
      });
    }
  }

  candidateResponses.responses = requirementResponses;

  const candidateResponse = await api.post(`work-permits/validate-candidate/region/${regionId}`, candidateResponses)
  .catch(err => console.error(err));


  if (candidateResponse) {
    const result = candidateResponse.hasOwnProperty('data') ?
      {
        eligibleWorkPermits:  candidateResponse.data.eligibleWorkPermits,
        ineligibleWorkPermits: candidateResponse.data.ineligibleWorkPermits,
      }
      :
      { eligibleWorkPermits: [], ineligibleWorkPermits: [] }

    return result;
  }
};

export {
  fetchStartDates,
  submitCandidateInformation,
};
