import React, { useContext, useEffect, useState, Fragment } from 'react';
import ResultsEligibility from '../components/ResultsEligibility';
import ResultsFullAssessmentRequired from '../components/ResultsFullAssessmentRequired';
import ResultsPermit from '../components/ResultsPermit';
import ResultsProcessingTime from '../components/ResultsProcessingTime';
import ResultsStartDate from '../components/ResultsStartDate';
import ResultsSections from '../components/ResultsSections';
import { AssessmentContext } from '../context/AssessmentContext';
import { CountryContext } from '../context/CountryContext';
import { Redirect } from 'react-router-dom';

const Results = () => {
  // Context Variables
  const [assessmentState, assessmentDispatch] = useContext(AssessmentContext);
  const [countryState, countryDispatch] = useContext(CountryContext);

  // State Variables
  const [redirectTo, setRedirectTo] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);

    if (!countryState.selectedDestinationCountry) {
      resetAssessment();
    } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryState.selectedDestinationCountry]);

  // Reset the assessment
  const resetAssessment = () => {
    countryDispatch({ type: 'RESET_COUNTRIES' });
    assessmentDispatch({ type: 'RESET_ASSESSMENT' });
    setRedirectTo('/');
  };
  

  return (
    redirectTo
    ? <Redirect to="/" />
    : <div className="gw__results-box row no-gutters justify-content-center">
        <div className="col-12 col-md-10 py-5 px-4 px-md-0 fadeInUp">
          {
            assessmentState.eligibleWorkPermits.length > 0 && 
            (assessmentState.eligibleWorkPermits[0].isFailingBasedOnFieldOfStudy || assessmentState.eligibleWorkPermits[0].isManuallyAssessed)
            ? <ResultsFullAssessmentRequired customOutput={assessmentState.eligibleWorkPermits[0].manualAssessmentResultText} resetAssessment={resetAssessment} />
            : <Fragment>
                <ResultsEligibility workPermits={assessmentState.eligibleWorkPermits} ineligibleWorkPermits={assessmentState.ineligibleWorkPermits}/>
                <ResultsPermit workPermits={assessmentState.eligibleWorkPermits} />
                <ResultsProcessingTime workPermits={assessmentState.eligibleWorkPermits} />
                <ResultsStartDate workPermits={assessmentState.eligibleWorkPermits} />
                <ResultsSections workPermits={assessmentState.eligibleWorkPermits} />
              
                <div className="d-flex justify-content-start mt-5">
                  <button className="btn btn-primary" onClick={() => window.print()}>SAVE AS PDF</button>
                  <button className="btn btn-link" onClick={resetAssessment}>CLOSE</button>
                </div> 
                <p className="gw__results-assessment-id mt-5">
                    {
                      assessmentState.eligibleWorkPermits.length > 0 &&
                      (
                        <>GoWork ID:  <span>{assessmentState.eligibleWorkPermits[0].assessmentId}</span></>
                      )
                    }
                </p>
                <div className='gw__disclaimer mt-5'>
                    <p>
                    Assumptions and Limitation: This assessment is indicative only. It is based on the information inputted into the tool by you, which has not been independently verified. Accordingly, while, indicative work permits/visas and associated processing times should be identified by the assessment they should not be considered as a substitute for a full analysis of all circumstances or qualifying criteria.
                    </p>
                    <p>
                    The assessment presented to you is based upon existing processing times and immigration* regulations which may change between the assessment date and the time of the immigration application.
                    </p>
                    <p>
                    No entity in the Deloitte Touche Tohmatsu Limited network of member firms, or their affiliates (“Deloitte Entity”) makes any guarantee of either (i) a successful immigration application or (ii) access to the intended location; this remains subject to the discretion of the relevant government immigration officials or their agents. In addition, no Deloitte Entity shall be responsible for any loss whatsoever and howsoever sustained by any person who relies on the information, the results of this assessment or for any consequential, special or similar damages, even if advised of the possibility of such damages.
                    </p>
                    <p>
                    *Note:  Immigration services are provided by the immigration practices of Deloitte Touche Tohmatsu Limited (“DTTL”) member firms, their affiliates and/or other approved third parties outside the United States or by Berry Appleman & Leiden LLP (“BAL LLP”) in the United States or Farrell LLP in Canada an immigration law firm allied with Deloitte LLP in Canada.
                    BAL LLP provides US immigration services under an alliance with Deloitte LLP. Deloitte Tax LLP does not provide immigration or legal services and is not a party to the alliance with BAL LLP.
                    </p>
                </div>
              </Fragment>
          }
        </div>
      </div>
  );
};

export default Results;
