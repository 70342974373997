import React, { useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import Select, { components } from 'react-select';
import { AssessmentContext } from '../context/AssessmentContext';
import { CountryContext } from '../context/CountryContext';
import { submitCandidateInformation } from '../services/AssessmentService';
import SnackBarWarning from '../components/SnackBarWarning';
import Loading from '../components/Loading';
import { CustomInput } from 'reactstrap';

// TODO ADD SERVICE FOR THE DROPDOWNS, DUPLICATE CODE

const Summary = () => {
  const [assessmentState, assessmentDispatch] = useContext(AssessmentContext);
  const [countryState, countryDispatch] = useContext(CountryContext);

  // State Variables
  const [citizenshipDropdownOptions, setCitizenshipDropdownOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isResponsesValid, setIsResponsesValid] = useState(true);
  const [jobPositionDropdownOptions, setJobPositionDropdownOptions] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [redirectTo, setRedirectTo] = useState('');
  const [requirementsMap, setRequirementsMap] = useState(null);
  const [sourceCountryDropdownOptions, setSourceCountryDropdownOptions] = useState([]);

  useEffect(() => {
    buildSourceCountryDropdown();
    buildJobPositionsDropdown();
    buildCitizenshipDropdown();
    buildRequirementsMap();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Create the dropdown options for source countries
  const buildSourceCountryDropdown = () => {
    let tempSourceCountries = [];

    for (const country of countryState.countries) {
      tempSourceCountries.push({
        label: country.name,
        value: country,
      });
    }

    setSourceCountryDropdownOptions(tempSourceCountries);
  };

  /**
   * Handle the event from selecting a source country
   * @param {object} event The selected source country
   */
  const handleSourceCountrySelection = event => {
    assessmentDispatch({
      type: 'SELECT_SOURCE_COUNTRY',
      selectedSourceCountry: event,
    });
  };

  // Create the dropdown options for job positions
  const buildJobPositionsDropdown = () => {
    let tempJobPositions = [];

    for (const jobPosition of countryState.jobPositions) {
      tempJobPositions.push({
        label: jobPosition.jobFamily,
        value: jobPosition,
      });
    }

    setJobPositionDropdownOptions(tempJobPositions.sort((a, b) => a.label.localeCompare(b.label)));
  };

  /**
   * Handle the event from selecting a job position
   * @param {object} event The selected job position
   */
  const handleJobPositionSelection = event => {
    assessmentDispatch({
      type: 'SELECT_JOB_POSITION',
      selectedJobPosition: event,
    });
  };

  // Create the dropdown options for citizenship
  const buildCitizenshipDropdown = () => {
    let tempCitizenships = [{
      label: 'Not disclosed',
      value: {
        country: {
          id: 0,
          name: 'Not disclosed',
        },
        region: {
          id: 0,
          name: 'Not disclosed',
        }
      },
    }];

    for (const country of countryState.countries) {
      if (country.regions[0]) {
        tempCitizenships.push({
          label: country.name,
          value: {
            country: {
              id: country.id,
              name: country.name,
            },
            region: {
              id: country.regions[0].id,
              name: country.regions[0].name,
            },
          },
        });
      }
    }

    setCitizenshipDropdownOptions(tempCitizenships);
  };

  /**
   * Handle the event from selecting a citizenship
   * @param {object} event The selected citizenship
   */
  const handleCitizenshipSelection = event => {
    assessmentDispatch({
      type: 'SELECT_CITIZENSHIP',
      selectedCitizenship: event,
    });
  };
  // Build a map containing the requirements data and dropdown options
  const buildRequirementsMap = () => {
    const tempRequirementsIndex = [];
    const tempRequirementsMap = new Map();
    for (const requirement of countryState.selectedDestinationCountryRequirements) {
      tempRequirementsIndex.push(requirement.id);
      const requirementObject = {
        requirement,
        requirementValuesDropdown: [],
      };

      // Check the type (for field of study)
      if (requirement.type === 'fieldOfStudy') {
        for (const fieldOfStudy of countryState.fieldsOfStudy) {
          requirementObject.requirementValuesDropdown.push({
            label: fieldOfStudy.name + ' - ' + fieldOfStudy.description,
            value: fieldOfStudy,
            type: 'fieldOfStudy',
          });
        }
      } else if (requirement.type === 'jobLevel') {
	      for (const jobLevel of countryState.jobLevels) {
		      requirementObject.requirementValuesDropdown.push({
			      label: jobLevel.name,
			      value: jobLevel,
			      type: 'jobLevel',
		      });
	      }
      } else if (requirement.type === 'salaryThreshold') {
        for (const salaryRange of requirement.values) {
          requirementObject.requirementValuesDropdown.push({
            label: salaryRange.value,
            value: salaryRange.min,
            type: 'salaryThreshold',
          });
        }
      } else if (requirement.values || requirement.type !== 'clientSpecificQuestion') {
        // Filter out the Not Required values TODO: MOVE THIS TO THE BACKEND
        for (const value of requirement.values) {
        if (value.requirementValueRevisions[0].value !== 'Not Required') {
          requirementObject.requirementValuesDropdown.push({
            label: value.requirementValueRevisions[0].value,
            value: value,
          });
        }
      }
    }

      tempRequirementsMap.set(requirement.id, requirementObject);
    }
    setRequirementsMap(tempRequirementsMap);
  };

  /**
   * Handle the event from selecting a requirement value
   * @param {object} selectedValue The selected value event
   * @param {number} requirementId The id of the requirement the value was selected from
   */
   let requirementResponsesTemp = assessmentState.requirementResponses;

  const handleRequiremenOptionSelect = (selectedValue, requirementId) => {
    requirementResponsesTemp.set(requirementId, selectedValue);

    assessmentDispatch({
      type: 'SELECT_REQUIREMENT_OPTION',
      requirementResponses: requirementResponsesTemp,
    });

    setIsResponsesValid(true);
    setRefresh(!refresh);
  };
  const handleClientSpecificQuestionAnswer = (selectedValue, requirementId) => {
    assessmentDispatch({
      type: 'SET_CANDIDATE_ID',
      clientSpecificQuestion: selectedValue,
    });
    let tempSelectedValue = {
      label: selectedValue,
      value: selectedValue,
      type: 'clientSpecificQuestion',
  };
    requirementResponsesTemp.set(requirementId, tempSelectedValue);

    assessmentDispatch({
      type: 'SELECT_REQUIREMENT_OPTION',
      requirementResponses: requirementResponsesTemp,
    });
    setIsResponsesValid(true);
    setRefresh(!refresh);
  };

  const submitResponses = async event => {
    event.preventDefault();

    let responsesValid = true;

    for (const requirement of countryState.selectedDestinationCountryRequirements) {
      if (!assessmentState.requirementResponses.get(requirement.id)) {
        responsesValid = false;
        setIsResponsesValid(false);
      }
    }

    if (!assessmentState.selectedSourceCountry || !assessmentState.selectedJobPosition || !assessmentState.selectedCitizenship) {
      responsesValid = false;
      setIsResponsesValid(false);
    }

    if (responsesValid) {
      setIsLoading(true);

      const candidateResponses = {
        sourceRegion: assessmentState.selectedSourceCountry.value.regions[0],
        sourceCountry: assessmentState.selectedSourceCountry.value,
        selectedCitizenship: assessmentState.selectedCitizenship.value,
        selectedExistingVisa: assessmentState.selectedExistingVisa,
        jobPosition: assessmentState.selectedJobPosition.value,
        responses: assessmentState.requirementResponses,
        assessmentStartTime: assessmentState.startTime,
        assessmentFinishTime: Date.now(),
        destinationRegion: countryState.selectedDestinationCountry.regions[0],
        destinationCountry: countryState.selectedDestinationCountry,
      };
      const candidateResponse = await submitCandidateInformation(countryState.selectedDestinationCountry.regions[0].id, candidateResponses, requirementsMap);

      if (candidateResponse) {
        assessmentDispatch({
          type: 'SET_ELIGIBLE_WORK_PERMITS',
          eligibleWorkPermits: candidateResponse.eligibleWorkPermits,
          ineligibleWorkPermits: candidateResponse.ineligibleWorkPermits,
        });

        setRedirectTo('/results');
      }
    }
  };

  // Reset the selected values and return back to the landing page
  const resetAssessment = () => {
    countryDispatch({ type: 'RESET_COUNTRIES' });
    assessmentDispatch({ type: 'RESET_ASSESSMENT' });
  };

  const DropdownIndicator = (props) => {
    return !props.isDisabled ? (
      <components.DropdownIndicator {...props}>
        <i className="icon-simple_arrow_down_special gw__requirement-select-icon" />
      </components.DropdownIndicator>
    ) : (
      <components.DropdownIndicator {...props}>
        <i className="icon-lock_special gw__requirement-select-icon text-muted" />
      </components.DropdownIndicator>
    );
  }

  return (
    redirectTo
    ? <Redirect to="/results" />
    : isLoading
      ? <Loading />
      : <div className="gw__summary-container fadeInUp">
          <div className="row">
            <div className="col-12 col-md-10 mx-auto">
              <p className="gw__summary-title">Review &amp; Submit</p>
            </div>
          </div>

          <form>
            {/* DESTINATION COUNTRY */}
            <div className ="row form-group">
              <div className="col-12 col-md-10 mx-auto mt-4">
                <h1 className="gw__summary-label--disabled">Destination Country</h1>
                <div className="mt-3">
                  <Select
                    className="gw__requirement-react-select-container"
                    classNamePrefix="gw__requirement-react-select"
                    inputId="select-destination-country"
                    isDisabled={true}
                    components={{DropdownIndicator}}
                    value={
                      countryState.selectedDestinationCountry
                      ? { label: countryState.selectedDestinationCountry.name, value: countryState.selectedDestinationCountry }
                      : null
                    }
                  />
                </div>
              </div>
            </div>

            {/* EXISTING VISA */}
            <div className ="row form-group">
              <div className="col-12 col-md-10 mx-auto mt-4">
                <h1 className="gw__summary-label--disabled">Existing Visa</h1>
                <div className="mt-3">
                  <Select
                    className="gw__requirement-react-select-container"
                    classNamePrefix="gw__requirement-react-select"
                    inputId="select-existing-visa"
                    isDisabled={true}
                    components={{DropdownIndicator}}
                    value={assessmentState.selectedExistingVisa ? assessmentState.selectedExistingVisa : { label: 'No', value: 'No' }}
                  />
                </div>
              </div>
            </div>

            {/* SOURCE COUNTRY */}
            <div className="row form-group">
              <div className="col-12 col-md-10 mx-auto mt-4">
                <h1 className="gw__summary-label">Current Location</h1>
                <div className="mt-3">
                  <Select
                    className="gw__requirement-react-select-container"
                    classNamePrefix="gw__requirement-react-select"
                    components={{DropdownIndicator}}
                    inputId="select-source-country"
                    onChange={(event) => handleSourceCountrySelection(event)}
                    options={sourceCountryDropdownOptions}
                    placeholder="Select source country"
                    value={assessmentState.selectedSourceCountry ? assessmentState.selectedSourceCountry : null}
                  />
                </div>
              </div>
            </div>

            {/* JOB POSITION */}
            <div className ="row form-group">
              <div className="col-12 col-md-10 mx-auto mt-4">
                <h1 className="gw__summary-label">Job Family</h1>
                <div className="mt-3">
                  <Select
                    className="gw__requirement-react-select-container"
                    classNamePrefix="gw__requirement-react-select"
                    components={{DropdownIndicator}}
                    inputId="select-job-position"
                    onChange={event => handleJobPositionSelection(event)}
                    options={jobPositionDropdownOptions}
                    placeholder="Select job position"
                    value={assessmentState.selectedJobPosition ? assessmentState.selectedJobPosition : null}
                  />
                </div>
              </div>
            </div>

            {/* CITIZENSHIP */}
            <div className ="row form-group">
              <div className="col-12 col-md-10 mx-auto mt-4">
                <h1 className="gw__summary-label">Citizenship</h1>
                <div className="mt-3">
                  <Select
                    className="gw__requirement-react-select-container"
                    classNamePrefix="gw__requirement-react-select"
                    components={{DropdownIndicator}}
                    inputId="select-citizenship"
                    onChange={event => handleCitizenshipSelection(event)}
                    options={citizenshipDropdownOptions}
                    placeholder="Select citizenship"
                    value={assessmentState.selectedCitizenship ? assessmentState.selectedCitizenship : null}
                  />
                </div>
              </div>
            </div>

            {/* CUSTOM REQUIREMENTS */}
            {
              requirementsMap && countryState.selectedDestinationCountryRequirements.map((requirement, index) => {
                if (requirement.type === 'fieldOfStudy') {
                  // Covers the first option in the lsit, which has a different class
                  let initialItems = document.getElementsByClassName('gw__requirement-react-select__option gw__requirement-react-select__option--is-focused css-1n7v3ny-option');
                  for (const initialItem of initialItems) {
                    let previousHTMLSplit = initialItem.innerHTML.split(' - ');

                    if (previousHTMLSplit.length > 1) {
                      let boldText = '<span style="color: #000000">' + previousHTMLSplit[0] + '</span>';
                      let notBoldText = '<span style="color: #757575">' + previousHTMLSplit[1] + '</span>';
                      initialItem.innerHTML = boldText + ' - ' + notBoldText;
                    }
                  }
              
                  // Covers the selected option
                  let selectedItems = document.getElementsByClassName('gw__requirement-react-select__option gw__requirement-react-select__option--is-selected css-9gakcf-option');
                  for (const selectedItem of selectedItems) {
                    let previousHTMLSplit = selectedItem.innerHTML.split(' - ');
                    
                    if (previousHTMLSplit.length > 1) {
                      let boldText = '<span style="color: #000000">' + previousHTMLSplit[0] + '</span>';
                      let notBoldText = '<span style="color: #757575">' + previousHTMLSplit[1] + '</span>';
                      selectedItem.innerHTML = boldText + ' - ' + notBoldText;
                    }
                  }
              
                  // Covers every other option
                  let regularItems = document.getElementsByClassName('gw__requirement-react-select__option css-yt9ioa-option');
                  for (const regularItem of regularItems) {
                    let previousHTMLSplit = regularItem.innerHTML.split(' - ');

                    if (previousHTMLSplit.length > 1) {
                      let boldText = '<span style="color: #000000">' + previousHTMLSplit[0] + '</span>';
                      let notBoldText = '<span style="color: #757575">' + previousHTMLSplit[1] + '</span>';
                      regularItem.innerHTML = boldText + ' - ' + notBoldText;
                    }
                  }
                }
                return (
                  <div className="row form-group" key={index}>
                    <div className="col-12 col-md-10 mx-auto mt-4">
                      <h1 className="gw__summary-label">{requirement.requirementRevisions[0].name}</h1>
                      <div className="mt-3" onClick={() => setRefresh(!refresh)}>
                        {requirement.type ==='clientSpecificQuestion' ?
                          <CustomInput  
                            placeholder="Enter The Candidate ID"
                            className="gw_free-text-input" 
                            type= "text" 
                            id="gw__candidate-id"
                            onChange={(event) => handleClientSpecificQuestionAnswer(event.target.value, requirement.id)}
                            size="50"
                            value={assessmentState.clientSpecificQuestion}
                            /> 
                            :
                          <Select
                            className="gw__requirement-react-select-container"
                            classNamePrefix="gw__requirement-react-select"
                            components={{DropdownIndicator}}
                            inputId={`select-${requirement.requirementRevisions[0].name}`}
                            onChange={event => handleRequiremenOptionSelect(event, requirement.id)}
                            options={requirementsMap.get(requirement.id).requirementValuesDropdown}
                            placeholder={`Select ${requirement.requirementRevisions[0].name}`}
                            value={assessmentState.requirementResponses.get(requirement.id)
                              ? assessmentState.requirementResponses.get(requirement.id)
                              : null
                            }
                          />
                        }
                      </div>
                    </div>
                  </div>
                );
              })
            }

            <div className="row form-group">
              <div className="col-12 col-md-10 mx-auto mt-5">
                <div className="d-flex">
                  <button className="btn btn-outline-green mr-4" onClick={resetAssessment}>END ASSESSMENT</button>
                  <button className="btn btn-primary" onClick={submitResponses}>SUBMIT</button>
                </div>
              </div>
            </div>
            {
              !isResponsesValid &&
              <div className="gw__snackbar-warning animate fadeIn">
                <SnackBarWarning
                  onClose={() => setIsResponsesValid(true)}
                  message={!isResponsesValid && 'Please fill all non-optional fields'}
                  aria="Please fill all fields"
                />
              </div>
            }
          </form>
        </div>
  );
};

export default Summary;
