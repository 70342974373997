import React, { Fragment, useEffect } from 'react';
import { logout } from '../services/AuthenticationService'

// TODO This component should be removed as logout occurs when session expires.
const SessionExpired = () => {
  useEffect(() => {
    window.scrollTo(0, 0);

    setTimeout(async () => {
      await logout();
    }, 3000);
  }, []);

  return (
    <Fragment>
      <div className="d-flex justify-content-center align-items-center gw__session-expired-message-container fade-in">
        <div className="d-flex justify-content-center align-items-center flex-column gw__session-expired-message">
          <h3>Your session has expired</h3>
          <h5 className="mt-3">You will be redirected to log-in page</h5>
        </div>
      </div>
      <div className="gw__session-expired-background"></div>
    </Fragment>
  );
};

export default SessionExpired;
