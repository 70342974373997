const STEP_INDEX = {
  existingVisa: 0,
  sourceCountry: 1,
  jobPosition: 2,
  citizenship: 3,
  requirements: 4,
  summary: 5,
};

export default STEP_INDEX;
