import React, { useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const Loading = (props) => {
  const [progress, setProgress] = React.useState(0);

  useEffect(() => {
    function tick() {
      setProgress(oldProgress => (oldProgress >= 100 ? 0 : oldProgress + 1));
    }

    const timer = setInterval(tick, 20);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <CircularProgress
      className="gw__loading-spinner"
      color="primary"
      variant="determinate"
      value={progress}
      thickness= {props.results ? 2 : 1}
      size={props.results ? 150 : 40}
    />
  );
}

export default Loading;
