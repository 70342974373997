/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import { withStyles } from "@material-ui/core/styles";
import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import TermsOfUseContent from "../components/TermsOfUseContent";

const ExpansionPanel = withStyles({
  root: {
    border: "none",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: "none",
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: "white",
    borderBottom: "1px solid #d1d1d1",
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const HelpAndSupport = ({ isModalOpen, toggleHelpAndSupportModal }) => {
  return (
    <Modal className="overflow-hidden" centered isOpen={isModalOpen} toggle={toggleHelpAndSupportModal}>
      <ModalHeader className="pb-0 pl-0" tag="div" wrapTag="div">
        <div className="d-flex justify-content-between pl-4">
          <div>
            <h1 className="gw__help-and-support-title mt-0">Immigration FAQs</h1>
          </div>
          <i className="icon-cross_special" onClick={toggleHelpAndSupportModal} />
        </div>
      </ModalHeader>
      <ModalBody className="pt-0 pb-5 px-0">
        <div className="modal-content-scroll px-4" style={{ maxHeight: "70vh" }}>
          <ExpansionPanel defaultExpanded={true}>
            <ExpansionPanelSummary>
              <div className="d-flex justify-content-between align-items-center w-100">
                <h6 className="gw__help-and-support-section-title">What is Deloitte GoWork?</h6>
                <i className="icon-simple_arrow_down_special gw__help-and-support-select-icon" />
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div className="gw__help-and-support-section-content">
                Deloitte GoWork is an aid to recruiters and mobility professionals to determine early immigration eligibility 
                in candidates, immigration processing times and the best possible start date. The assessment aims 
                to optimise the hiring experience for both candidates and recruiters, providing early insight into any 
                immigration actions or roadblocks.
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel defaultExpanded={false}>
            <ExpansionPanelSummary>
              <div className="d-flex justify-content-between align-items-center w-100">
                <h6 className="gw__help-and-support-section-title">Planning a start date?</h6>
                <i className="icon-simple_arrow_down_special gw__help-and-support-select-icon" />
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div className="gw__help-and-support-section-content">
                <p>
                  Work permit and visa processing can take significant time. 
                  If your candidate requires a work visa, ensure you take visa processing times into account before planning your candidate's start date.
                </p>

                <p className="gw__help-and-support-bold">Seasonal fluctuations</p>
                <p>
                  During certain times of the year, such as the holiday periods, processing times can be lengthened default to increased demand in 
                  applications with the authorities. Where authorized, Deloitte or BAL will advise you of these processing times when your 
                  candidate's matter is commenced.
                </p>

                <p>
                  <span className="gw__help-and-support-bold">REMEMBER:</span> Normal processing times found
                  on the country pages will be affected.
                </p>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel defaultExpanded={false}>
            <ExpansionPanelSummary>
              <div className="d-flex justify-content-between align-items-center w-100">
                <h6 className="gw__help-and-support-section-title">What are our Terms of Use?</h6>
                <i className="icon-simple_arrow_down_special gw__help-and-support-select-icon" />
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div className="gw__help-and-support-section-content">
                <TermsOfUseContent />
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <h1 className="gw__help-and-support-title mt-4 mb-2">Need more help?</h1>
          <p className="gw__help-and-support-section-content">
          For help on GoWork or to request a detailed assessment please contact <a className="gw__help-and-support-link" href="mailto:UKDeloitteGoWorkSupport@deloitte.co.uk">UKDeloitteGoWorkSupport@deloitte.co.uk</a>
          <br /><br />
          Please help us by including as much information about your query as possible.  
          If you have a question on an assessment result please attach a PDF of the output that 
          can be generated from the assessment output page and send this to our team for further analysis.
          <br /><br />
          For questions related to hiring, mobility or immigration policy or any other internal company related matter please contact your immigration or mobility contact.

          </p>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default HelpAndSupport;
