import { authApi } from '../api';

const api = authApi();

/**
 * Fetch all the countries (used for citizenship)
 */
const fetchCountries = async () => {
  const countriesResponse = await api.get('/countries');

  if (countriesResponse) {
    return countriesResponse.hasOwnProperty('data') ? countriesResponse.data : [];
  }
}

/**
 * Fetch the countries which the client has configured for them
 */
const fetchDestinationCountries = async () => {
  const destinationCountriesResponse = await api.get('/destinationCountries');

  if (destinationCountriesResponse) {
    return destinationCountriesResponse.hasOwnProperty('data') ? destinationCountriesResponse.data : [];
  }
};

/**
 * Fetch the requirements for the selected country
 * @param {number} countryId The id of the selected country
 * @param {number} regionId The id of the selected region
 */
const fetchDestinationCountryRequirements = async (countryId, regionId) => {
  const destinationCountryRequirementsResponse = await api.get(`/countries/${countryId}/regions/${regionId}/requirements`);

  if (destinationCountryRequirementsResponse) {
    return destinationCountryRequirementsResponse.hasOwnProperty('data') ? destinationCountryRequirementsResponse.data : [];
  }
};

/**
 * Retrieve the fields of study
 * @returns {array} An array containing the fields of study
 */
const fetchFieldsOfStudy = async () => {
  const fieldsOfStudyResponse = await api.get(`/fields-of-study`);

  if (fieldsOfStudyResponse) {
    return fieldsOfStudyResponse.hasOwnProperty('data') ? fieldsOfStudyResponse.data : [];
  }
};

export {
  fetchCountries,
  fetchDestinationCountries,
  fetchDestinationCountryRequirements,
  fetchFieldsOfStudy,
};