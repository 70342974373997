import React, { useState,useContext } from 'react';
import Select, {components} from 'react-select';
import { AssessmentContext } from '../context/AssessmentContext';
import { CustomInput } from 'reactstrap';

const AssessmentQuestion = ({ description, disabled, onChangeHandler, options, placeholder, question, type, value,handleClientSpecificQuestionAnswer}) => {
  // State variables
  const [refresh, setRefresh] = useState(true);
  const [assessmentState] = useContext(AssessmentContext);

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <i className="icon-simple_arrow_down_special gw__requirement-select-icon" />
      </components.DropdownIndicator>
    );
  };

  // Update the field of study dropdown to highlight the name and give a grey font to the description section after the '-'
  if (type === 'fieldOfStudy') {
    // Covers the first option in the lsit, which has a different class
    let initialItems = document.getElementsByClassName('gw__requirement-react-select__option gw__requirement-react-select__option--is-focused css-1n7v3ny-option');
    for (const initialItem of initialItems) {
      let previousHTMLSplit = initialItem.innerHTML.split(' - ');
      let boldText = '<span style="color: #000000">' + previousHTMLSplit[0] + '</span>';
      let notBoldText = '<span style="color: #757575">' + previousHTMLSplit[1] + '</span>';
      initialItem.innerHTML = boldText + ' - ' + notBoldText;
    }

    // Covers the selected option
    let selectedItems = document.getElementsByClassName('gw__requirement-react-select__option gw__requirement-react-select__option--is-selected css-9gakcf-option');
    for (const selectedItem of selectedItems) {
      let previousHTMLSplit = selectedItem.innerHTML.split(' - ');
      let boldText = '<span style="color: #000000">' + previousHTMLSplit[0] + '</span>';
      let notBoldText = '<span style="color: #757575">' + previousHTMLSplit[1] + '</span>';
      selectedItem.innerHTML = boldText + ' - ' + notBoldText;
    }

    // Covers every other option
    let regularItems = document.getElementsByClassName('gw__requirement-react-select__option css-yt9ioa-option');
    for (const regularItem of regularItems) {
      let previousHTMLSplit = regularItem.innerHTML.split(' - ');
      let boldText = '<span style="color: #000000">' + previousHTMLSplit[0] + '</span>';
      let notBoldText = '<span style="color: #757575">' + previousHTMLSplit[1] + '</span>';
      regularItem.innerHTML = boldText + ' - ' + notBoldText;
    }
  }

  return (
    <div className="gw__assessment-container">
      <p className="gw__assessment-question mb-3 mb-md-4">{question}</p>  
      <p className="gw__assessment-description mb-3 mb-md-4">{description}</p>

      <div onClick={() => setRefresh(!refresh)}>
        {type === 'clientSpecificQuestion' ? 
        //need to style it and the value execution.
          <CustomInput  
            className="gw_free-text-input" 
            type= "text" 
            placeholder="Enter The Candidate ID"
            id="gw__candidate-id"
            onChange={(event) => handleClientSpecificQuestionAnswer(event.target.value)}
            size="50"
            value={assessmentState.clientSpecificQuestion}
            /> 
            :
          <Select
            className="gw__requirement-react-select-container"
            classNamePrefix="gw__requirement-react-select"
            components={{DropdownIndicator}}
            isDisabled={disabled === true}
            onChange={(event) => onChangeHandler(event)}
            options={options}
            placeholder={placeholder}
            value={value ? value : null}
          />
        }
      </div>
    </div>
    
  );
}

export default AssessmentQuestion;
