import React from 'react'

const IconCross = () => {
  return (
    <svg viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <circle stroke="#E53030" strokeWidth="2" cx="30" cy="30" r="29"/>
        <path d="M31.41 30l10.3-10.29a1 1 0 0 0-1.41-1.41L30 28.59l-10.29-10.3a1 1 0 0 0-1.41 1.41L28.59 30l-10.3 10.29a1 1 0 1 0 1.41 1.41L30 31.41l10.29 10.3a1 1 0 0 0 1.41-1.41L31.41 30z" fill="#E53030" fillRule="nonzero"/>
        <path d="M6 6h48v48H6z"/>
      </g>
    </svg>


  )
}

export default IconCross
